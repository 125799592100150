import {
  TEST_NOTIFICATION_FAILURE,
  TEST_NOTIFICATION_SUCCESS,
  TEST_NOTIFICATION_REQUEST,
} from "../constants/notificationConstants";

function testNotificationReducer(state = { }, action) {
  switch (action.type) {
    case TEST_NOTIFICATION_REQUEST:
      return { loading: true };
    case TEST_NOTIFICATION_SUCCESS:
      return { loading: false, success: true };
    case TEST_NOTIFICATION_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { testNotificationReducer };
