import {
  STRATEGY_ACTIVATE_FAILURE,
  STRATEGY_ACTIVATE_REQUEST,
  STRATEGY_ACTIVATE_SUCCESS,
  STRATEGY_CREATE_FAILURE,
  STRATEGY_CREATE_REQUEST,
  STRATEGY_CREATE_SUCCESS,
  STRATEGY_DEACTIVATE_FAILURE,
  STRATEGY_DEACTIVATE_REQUEST,
  STRATEGY_DEACTIVATE_SUCCESS,
  STRATEGY_DETAILS_FAILURE,
  STRATEGY_DETAILS_REQUEST,
  STRATEGY_DETAILS_SUCCESS,
  STRATEGY_HISTORIES_FAILURE,
  STRATEGY_HISTORIES_REQUEST,
  STRATEGY_HISTORIES_SUCCESS,
  STRATEGY_LIST_FAILURE,
  STRATEGY_LIST_REQUEST,
  STRATEGY_LIST_SUCCESS,
  STRATEGY_UPDATE_FAILURE,
  STRATEGY_UPDATE_REQUEST,
  STRATEGY_UPDATE_SUCCESS,
} from "../constants/strategyConstants";

function strategyListReducer(state = { strategies: [] }, action) {
  switch (action.type) {
    case STRATEGY_LIST_REQUEST:
      return { loading: true, strategies: [] };
    case STRATEGY_LIST_SUCCESS:
      return { loading: false, strategies: action.payload };
    case STRATEGY_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function strategyHistoriesReducer(state = { histories: [] }, action) {
  switch (action.type) {
    case STRATEGY_HISTORIES_REQUEST:
      return { loading: true, histories: [] };
    case STRATEGY_HISTORIES_SUCCESS:
      return { loading: false, histories: action.payload };
    case STRATEGY_HISTORIES_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function strategyDetailsReducer(state = { strategy: {} }, action) {
  switch (action.type) {
    case STRATEGY_DETAILS_REQUEST:
      return { loading: true };
    case STRATEGY_DETAILS_SUCCESS:
      return { loading: false, strategy: action.payload };
    case STRATEGY_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function strategyCreateReducer(state = { strategy: {} }, action) {
  switch (action.type) {
    case STRATEGY_CREATE_REQUEST:
      return { loading: true };
    case STRATEGY_CREATE_SUCCESS:
      return { loading: false, success: true, strategy: action.payload };
    case STRATEGY_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function strategyUpdateReducer(state = { strategy: {} }, action) {
  switch (action.type) {
    case STRATEGY_UPDATE_REQUEST:
      return { loading: true };
    case STRATEGY_UPDATE_SUCCESS:
      return { loading: false, success: true, strategy: action.payload };
    case STRATEGY_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function strategyDeactivateReducer(state = { strategy: {} }, action) {
  switch (action.type) {
    case STRATEGY_DEACTIVATE_REQUEST:
      return { loading: true };
    case STRATEGY_DEACTIVATE_SUCCESS:
      return { loading: false, strategy: action.payload, success: true };
    case STRATEGY_DEACTIVATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function strategyActivateReducer(state = { strategy: {} }, action) {
  switch (action.type) {
    case STRATEGY_ACTIVATE_REQUEST:
      return { loading: true };
    case STRATEGY_ACTIVATE_SUCCESS:
      return { loading: false, strategy: action.payload, success: true };
    case STRATEGY_ACTIVATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  strategyDetailsReducer,
  strategyHistoriesReducer,
  strategyListReducer,
  strategyDeactivateReducer,
  strategyActivateReducer,
  strategyCreateReducer,
  strategyUpdateReducer,
};
