import {
  CURRENCY_BALANCE_FAILURE,
  CURRENCY_BALANCE_REQUEST,
  CURRENCY_BALANCE_SUCCESS,
  CURRENCY_TICKER_FAILURE,
  CURRENCY_TICKER_REQUEST,
  CURRENCY_TICKER_SUCCESS,
  EXCHANGE_LIST_FAILURE,
  EXCHANGE_LIST_REQUEST,
  EXCHANGE_LIST_SUCCESS,
} from "../constants/exchangeConstants";

function currencyBalanceExchangeReducer(state = { currencies: [] }, action) {
  switch (action.type) {
    case CURRENCY_BALANCE_REQUEST:
      return { loading: true };
    case CURRENCY_BALANCE_SUCCESS:
      return { loading: false, currencyBalance: action.payload };
    case CURRENCY_BALANCE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function currencyTickerExchangeReducer(state = { currency: {} }, action) {
  switch (action.type) {
    case CURRENCY_TICKER_REQUEST:
      return { loading: true };
    case CURRENCY_TICKER_SUCCESS:
      return { loading: false, currencyTicker: action.payload };
    case CURRENCY_TICKER_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function exchangeListReducer(state = { currency: {} }, action) {
  switch (action.type) {
    case EXCHANGE_LIST_REQUEST:
      return { loading: true };
    case EXCHANGE_LIST_SUCCESS:
      return { loading: false, exchanges: action.payload };
    case EXCHANGE_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { currencyBalanceExchangeReducer, currencyTickerExchangeReducer, exchangeListReducer };
