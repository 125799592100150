export const CURRENCY_DETAILS_REQUEST = "CURRENCY_DETAILS_REQUEST";
export const CURRENCY_DETAILS_SUCCESS = "CURRENCY_DETAILS_SUCCESS";
export const CURRENCY_DETAILS_FAILURE = "CURRENCY_DETAILS_FAILURE";

export const CURRENCY_DETAILS_BY_SYMBOL_REQUEST = "CURRENCY_DETAILS_BY_SYMBOL_REQUEST";
export const CURRENCY_DETAILS_BY_SYMBOL_SUCCESS = "CURRENCY_DETAILS_BY_SYMBOL_SUCCESS";
export const CURRENCY_DETAILS_BY_SYMBOL_FAILURE = "CURRENCY_DETAILS_BY_SYMBOL_FAILURE";

export const CURRENCY_LIST_REQUEST = "CURRENCY_LIST_REQUEST";
export const CURRENCY_LIST_SUCCESS = "CURRENCY_LIST_SUCCESS";
export const CURRENCY_LIST_FAILURE = "CURRENCY_LIST_FAILURE";

export const CURRENCY_SAVE_REQUEST = "CURRENCY_SAVE_REQUEST";
export const CURRENCY_SAVE_SUCCESS = "CURRENCY_SAVE_SUCCESS";
export const CURRENCY_SAVE_FAILURE = "CURRENCY_SAVE_FAILURE";

export const CURRENCY_DEACTIVATE_REQUEST = 'CURRENCY_DEACTIVATE_REQUEST';
export const CURRENCY_DEACTIVATE_SUCCESS = 'CURRENCY_DEACTIVATE_SUCCESS';
export const CURRENCY_DEACTIVATE_FAILURE = 'CURRENCY_DEACTIVATE_FAILURE';
