export const POSITION_DETAILS_REQUEST = "POSITION_DETAILS_REQUEST";
export const POSITION_DETAILS_SUCCESS = "POSITION_DETAILS_SUCCESS";
export const POSITION_DETAILS_FAILURE = "POSITION_DETAILS_FAILURE";

export const POSITION_LIST_REQUEST = "POSITION_LIST_REQUEST";
export const POSITION_LIST_SUCCESS = "POSITION_LIST_SUCCESS";
export const POSITION_LIST_FAILURE = "POSITION_LIST_FAILURE";

export const POSITION_OPEN_LIST_REQUEST = "POSITION_OPEN_LIST_REQUEST";
export const POSITION_OPEN_LIST_SUCCESS = "POSITION_OPEN_LIST_SUCCESS";
export const POSITION_OPEN_LIST_FAILURE = "POSITION_OPEN_LIST_FAILURE";

export const POSITION_SEARCH_REQUEST = "POSITION_SEARCH_REQUEST";
export const POSITION_SEARCH_SUCCESS = "POSITION_SEARCH_SUCCESS";
export const POSITION_SEARCH_FAILURE = "POSITION_SEARCH_FAILURE";

export const POSITION_SAVE_REQUEST = "POSITION_SAVE_REQUEST";
export const POSITION_SAVE_SUCCESS = "POSITION_SAVE_SUCCESS";
export const POSITION_SAVE_FAILURE = "POSITION_SAVE_FAILURE";

export const POSITION_DEACTIVATE_REQUEST = 'POSITION_DEACTIVATE_REQUEST';
export const POSITION_DEACTIVATE_SUCCESS = 'POSITION_DEACTIVATE_SUCCESS';
export const POSITION_DEACTIVATE_FAILURE = 'POSITION_DEACTIVATE_FAILURE';
