import {
  PORTFOLIO_DETAILS_REQUEST,
  PORTFOLIO_DETAILS_SUCCESS,
  PORTFOLIO_DETAILS_FAILURE,
  PORTFOLIO_LIST_FAILURE,
  PORTFOLIO_LIST_REQUEST,
  PORTFOLIO_LIST_SUCCESS,
  PORTFOLIO_SAVE_FAILURE,
  PORTFOLIO_SAVE_REQUEST,
  PORTFOLIO_SAVE_SUCCESS,
} from "../constants/portfolioConstants";

function portfolioListReducer(state = { portfolios: [] }, action) {
  switch (action.type) {
    case PORTFOLIO_LIST_REQUEST:
      return { loading: true, portfolios: [] };
    case PORTFOLIO_LIST_SUCCESS:
      return { loading: false, portfolios: action.payload };
    case PORTFOLIO_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function portfolioDetailsReducer(state = { portfolio: {} }, action) {
  switch (action.type) {
    case PORTFOLIO_DETAILS_REQUEST:
      return { loading: true };
    case PORTFOLIO_DETAILS_SUCCESS:
      return { loading: false, portfolio: action.payload };
    case PORTFOLIO_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function portfolioSaveReducer(state = { portfolio: {} }, action) {
  switch (action.type) {
    case PORTFOLIO_SAVE_REQUEST:
      return { loading: true };
    case PORTFOLIO_SAVE_SUCCESS:
      return { loading: false, success: true, portfolio: action.payload };
    case PORTFOLIO_SAVE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { portfolioSaveReducer, portfolioDetailsReducer, portfolioListReducer };
