import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportSnapshot } from "..//actions/reportActions";
import Moment from "react-moment";

function ReportsScreen(props) {
  const snapshotReport = useSelector((state) => state.snapshotReport);
  const { report: snapshot, loading: reportLoading, error: reportError } = snapshotReport;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "PMDash - Home";
    if (!userInfo) {
      props.history.push("/signin?redirect=reports");
    }
    return () => {};
  }, [userInfo]);

  useEffect(() => {
    dispatch(reportSnapshot());
    return () => {};
  }, []);

  return (
    <div className="content content-margined">
      <h3>Reports</h3>
      <div>
        {reportLoading ? (
          <div>Loading Report...</div>
        ) : reportError ? (
          <div>{reportError}</div>
        ) : (
          <div>
            <h3>Daily Snapshot Report</h3>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="details-action">
                      <ul>
                        <li>
                          <h4>General Inforamtion</h4>
                        </li>
                        <li># Outstanding Positions : {snapshot.outstandingPositions}</li>
                        <li># Active Stratgies : {snapshot.numberOfActiveStratgies}</li>
                        <li># Shutting Stratgies : {snapshot.numberOfShuttingDownStratgies}</li>
                        <li>
                          Cut date : <Moment>{snapshot.cutDate}</Moment>
                        </li>
                        <li>
                          Created On : <Moment>{snapshot.createdOn}</Moment>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="details-action">
                      <ul>
                        <li>
                          <h4>Last Day</h4>
                        </li>
                        <li>Daily Profit : {Number(snapshot.dailyFiatProfit)?.toFixed(2)} </li>
                        <li>Daily Fees : {Number(snapshot.dailyFeesInUsd)?.toFixed(2)}</li>
                        <li>Daily Crypto Gain : {Number(snapshot.dailyCryptoProfitInUsd)?.toFixed(2)}</li>
                        <li>Position Closed: {snapshot.dailyClosedPositions}</li>
                        <li>Position Opened: {snapshot.dailyOpenedPositions}</li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="details-action">
                      <ul>
                        <li>
                          <h4>Week</h4>
                        </li>
                        <li>Weekly Profit : {Number(snapshot.weeklyFiatProfit)?.toFixed(2)}</li>
                        <li>Weekly Fees : {Number(snapshot.weeklyFeesInUsd)?.toFixed(2)}</li>
                        <li>Weekly Crypto Gain : {Number(snapshot.weeklyCryptoProfitInUsd)?.toFixed(2)}</li>
                        <li>Position Closed: {snapshot.weeklyClosedPositions}</li>
                        <li>Position Opened: {snapshot.weeklyOpenedPositions}</li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="details-action">
                      <ul>
                        <li>
                          <h4>30 Days</h4>
                        </li>
                        <li>30 Days Profit : {Number(snapshot.thirtyFiatProfit)?.toFixed(2)}</li>
                        <li>30 Days Fees : {Number(snapshot.thirtyDaysFeesInUsd)?.toFixed(2)}</li>
                        <li>30 Days Crypto Gain : {Number(snapshot.thirtyCryptoProfitInUsd)?.toFixed(2)}</li>
                        <li>Position Closed: {snapshot.thirtyDaysClosedPositions}</li>
                        <li>Position Opened: {snapshot.thirtyDaysOpenedPositions}</li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="details-action">
                      <ul>
                        <li>
                          <h4>Year</h4>
                        </li>
                        <li>Yearly Profit : {Number(snapshot.yearlyFiatProfit)?.toFixed(2)}</li>
                        <li>Yearly Fees : {Number(snapshot.yearlyFeesInUsd)?.toFixed(2)}</li>
                        <li>Yearly Crypto Gain : {Number(snapshot.yearlyCryptoProfitInUsd)?.toFixed(2)}</li>
                        <li>Position Closed: {snapshot.yearlyClosedPositions}</li>
                        <li>Position Opened: {snapshot.yearlyOpenedPositions}</li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="details-action">
                      <ul>
                        <li>
                          <h4>All Time</h4>
                        </li>
                        <li>Total Profit : {Number(snapshot.totalFiatProfit)?.toFixed(2)}</li>
                        <li>Total Fees : {Number(snapshot.totalFeesInUsd)?.toFixed(2)}</li>
                        <li>Total Crypto Gain : {Number(snapshot.totalCryptoProfitInUsd)?.toFixed(2)}</li>
                        <li>Position Closed: {snapshot.totalClosedPositions}</li>
                        <li>Position Opened: {snapshot.totalOpenedPositions}</li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportsScreen;
