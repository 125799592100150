import axios from "axios";
import {
  CURRENCY_DETAILS_BY_SYMBOL_FAILURE,
  CURRENCY_DETAILS_BY_SYMBOL_REQUEST,
  CURRENCY_DETAILS_BY_SYMBOL_SUCCESS,
  CURRENCY_DETAILS_FAILURE,
  CURRENCY_DETAILS_REQUEST,
  CURRENCY_DETAILS_SUCCESS,
  CURRENCY_LIST_FAILURE,
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_SAVE_FAILURE,
  CURRENCY_SAVE_REQUEST,
  CURRENCY_SAVE_SUCCESS,
} from "../constants/currencyConstants";

const listCurrencies = (offset, size) => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_LIST_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/currency/paged?offset=" + offset + "&size=" + size, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: CURRENCY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CURRENCY_LIST_FAILURE, payload: error.message });
  }
};

const detailsCurrency = (currencyId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_DETAILS_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    let url = "/api/currency/" + currencyId;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });
    dispatch({ type: CURRENCY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CURRENCY_DETAILS_FAILURE, payload: error.message });
  }
};

const detailsCurrencyBySymbol = (symbol) => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_DETAILS_BY_SYMBOL_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();

    let url = "/api/currency/symbol/" + symbol;

    const { data } = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });
    dispatch({ type: CURRENCY_DETAILS_BY_SYMBOL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CURRENCY_DETAILS_BY_SYMBOL_FAILURE, payload: error.message });
  }
};

const saveCurrency = (currency) => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_SAVE_REQUEST, payload: currency });
    const {
      userSignin: { userInfo },
    } = getState();
    if (currency._id) {
      const { data } = await axios.put("/api/currency/" + currency._id, currency, {
        headers: {
          Authorization: "Bearer " + userInfo.token,
        },
      });
      dispatch({ type: CURRENCY_SAVE_SUCCESS, payload: data });
    } else {
      const { data } = await axios.post("/api/currency", currency, {
        headers: {
          Authorization: "Bearer " + userInfo.token,
        },
      });
      dispatch({ type: CURRENCY_SAVE_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({ type: CURRENCY_SAVE_FAILURE, payload: error.message });
  }
};

export { listCurrencies, saveCurrency, detailsCurrency, detailsCurrencyBySymbol };
