import axios from "axios";
import {
  TEST_NOTIFICATION_FAILURE,
  TEST_NOTIFICATION_SUCCESS,
  TEST_NOTIFICATION_REQUEST,
} from "../constants/notificationConstants";

const testNotification = (text) => async (dispatch, getState) => {
  try {
    dispatch({ type: TEST_NOTIFICATION_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/notification/test?text=" + text, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });
    dispatch({ type: TEST_NOTIFICATION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: TEST_NOTIFICATION_FAILURE, payload: error.message });
  }
};

export { testNotification };
