export const STRATEGY_DETAILS_REQUEST = "STRATEGY_DETAILS_REQUEST";
export const STRATEGY_DETAILS_SUCCESS = "STRATEGY_DETAILS_SUCCESS";
export const STRATEGY_DETAILS_FAILURE = "STRATEGY_DETAILS_FAILURE";

export const STRATEGY_LIST_REQUEST = "STRATEGY_LIST_REQUEST";
export const STRATEGY_LIST_SUCCESS = "STRATEGY_LIST_SUCCESS";
export const STRATEGY_LIST_FAILURE = "STRATEGY_LIST_FAILURE";

export const STRATEGY_HISTORIES_REQUEST = "STRATEGY_HISTORIESE_REQUEST";
export const STRATEGY_HISTORIES_SUCCESS = "STRATEGY_HISTORIES_SUCCESS";
export const STRATEGY_HISTORIES_FAILURE = "STRATEGY_HISTORIES_FAILURE";

export const STRATEGY_UPDATE_REQUEST = "STRATEGY_UPDATE_REQUEST";
export const STRATEGY_UPDATE_SUCCESS = "STRATEGY_UPDATE_SUCCESS";
export const STRATEGY_UPDATE_FAILURE = "STRATEGY_UPDATE_FAILURE";

export const STRATEGY_CREATE_REQUEST = "STRATEGY_CREATE_REQUEST";
export const STRATEGY_CREATE_SUCCESS = "STRATEGY_CREATE_SUCCESS";
export const STRATEGY_CREATE_FAILURE = "STRATEGY_CREATE_FAILURE";

export const STRATEGY_SHUTDOWN_REQUEST = "SSTRATEGY_SHUTDOWN_REQUEST";
export const STRATEGY_SHUTDOWN_SUCCESS = "STRATEGY_SHUTDOWN_SUCCESS";
export const STRATEGY_SHUTDOWN_FAILURE = "STRATEGY_SHUTDOWN_FAILURE";

export const STRATEGY_UNSHUTDOWN_REQUEST = "SSTRATEGY_UNSHUTDOWN_REQUEST";
export const STRATEGY_UNSHUTDOWN_SUCCESS = "STRATEGY_UNSHUTDOWN_SUCCESS";
export const STRATEGY_UNSHUTDOWN_FAILURE = "STRATEGY_UNSHUTDOWN_FAILURE";

export const STRATEGY_DEACTIVATE_REQUEST = 'STRATEGY_DEACTIVATE_REQUEST';
export const STRATEGY_DEACTIVATE_SUCCESS = 'STRATEGY_DEACTIVATE_SUCCESS';
export const STRATEGY_DEACTIVATE_FAILURE = 'STRATEGY_DEACTIVATE_FAILURE';

export const STRATEGY_ACTIVATE_REQUEST = 'STRATEGY_ACTIVATE_REQUEST';
export const STRATEGY_ACTIVATE_SUCCESS = 'STRATEGY_ACTIVATE_SUCCESS';
export const STRATEGY_ACTIVATE_FAILURE = 'STRATEGY_ACTIVATE_FAILURE';
