import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  activateStrategy,
  deactivateStrategy,
  detailsStrategy,
  shutdownStrategy,
  unshutdownStrategy,
} from "../actions/strategyActions";
import { searchPositionsByStrategyId } from "../actions/positionActions";
import Moment from "react-moment";
import { tickerCurrencyExchange } from "../actions/exchangeActions";

function StrategyDetailScreen(props) {
  const strategyDetails = useSelector((state) => state.strategyDetails);
  const { strategy, loading, error } = strategyDetails;
  const { positions: strategyPositions } = useSelector((state) => state.positionSearchByStrategyId);
  const { currencyTicker } = useSelector((state) => state.exchangeTicker);

  let openPositions = strategyPositions?.filter(
    (position) => position.state === "Open" || position.state === "PendingSell" || position.state === "PendingBuy"
  );
  let closedPositions = strategyPositions?.filter(
    (position) => position.state !== "Open" && position.state !== "PendingSell" && position.state !== "PendingBuy"
  );
  let openPositionRowCount = 1,
    closePositionRowCount = 1;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsStrategy(props.match.params.id));
    dispatch(searchPositionsByStrategyId(props.match.params.id));
    dispatch(tickerCurrencyExchange(strategy.currencySymbol));
    return () => {};
  }, []);

  const deactivateHandler = (strategy) => {
    dispatch(deactivateStrategy(strategy.id));
    props.history.push("/strategy/id/" + strategy.id);
  };

  const activateHandler = (strategy) => {
    dispatch(activateStrategy(strategy.id));
    props.history.push("/strategy/id/" + strategy.id);
  };

  const shutdownHandler = (strategy) => {
    dispatch(shutdownStrategy(strategy.id));
    props.history.push("/strategy/id/" + strategy.id);
  };

  const unshutdownHandler = (strategy) => {
    dispatch(unshutdownStrategy(strategy.id));
    props.history.push("/strategy/id/" + strategy.id);
  };

  const getGainedQuantity = () => {
    return closedPositions
      .reduce(
        (currentValue, position) =>
          Number(currentValue) + Number(position.openQuantity) - Number(position.closeQuantity),
        0
      )
      .toFixed(2);
  };

  const getHeldQuantity = () => {
    return openPositions
      .reduce((currentValue, position) => Number(currentValue) + Number(position.openQuantity), 0)
      .toFixed(2);
  };

  return (
    <div>
      <div className="back-to-home">
        <Link to="/strategies">Back to Strategies</Link>
      </div>
      {loading ? (
        <div>Loading..</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div>
          <div className="details">
            <div className="details-info">
              <ul>
                <li>
                  <h4>{strategy.name}</h4>
                </li>
                <li>
                  Currency: <Link to={"/currency/symbol/" + strategy.currencySymbol}>{strategy.currencyName}</Link>{" "}
                  (Currenct Price: {currencyTicker ? currencyTicker.close + "$" : "NA"})
                </li>
                <li>Exchange: {strategy.cryptocurrencyExchangeName}</li>
                <li>
                  Budget: ${Number(strategy.budget).toFixed(2)} (${Number(strategy.originalBudget).toFixed(2)})
                </li>
                <li>Balance: ${Number(strategy.balance).toFixed(2)}</li>
                <li>
                  Version: {strategy.version}
                  <div>
                    <Link to={"/strategy/history/" + strategy.id}>View versions</Link>
                  </div>
                </li>
                <li>
                  Description:
                  <div>{strategy.description}</div>
                </li>
                {strategy.nickname ? (
                  <li>
                    Nick Name: <div>{strategy.nickname}</div>
                  </li>
                ) : (
                  <div></div>
                )}
                <li>Drop buy percent: {strategy.buyDropInPercent}%</li>
                <li>Sell spike percent: {strategy.sellSpikeInPercent}%</li>
              </ul>
            </div>
            <div className="details-info">
              <ul>
                <li>
                  <h3>Profits and Fees</h3>
                </li>
                <li>
                  Held in Orders: {getHeldQuantity()}
                  {strategy.currencySymbol} (currently worth -
                  {currencyTicker ? " $" + (Number(getHeldQuantity()) * Number(currencyTicker.close)).toFixed(2) : "NA"}
                  )
                </li>
                <li>
                  Total Quantity Gained:{getGainedQuantity()}
                  {strategy.currencySymbol} (currently worth -
                  {currencyTicker
                    ? " $" + (Number(getGainedQuantity()) * Number(currencyTicker.close)).toFixed(2)
                    : "NA"}
                  )
                </li>
                <li>
                  Total Profit:
                  {" $" +
                    closedPositions
                      .reduce(
                        (currentValue, position) =>
                          Number(currentValue) +
                          (Number(position.closeExecutedValueInUsd) -
                            Number(position.openExecutedValueInUsd) -
                            Number(position.closeFeeInUsd) -
                            Number(position.openFeeInUsd)),
                        0
                      )
                      .toFixed(2)}
                </li>
                <li>
                  Total Open Fees:
                  {" $" +
                    closedPositions
                      .reduce((currentValue, position) => Number(currentValue) + Number(position.openFeeInUsd), 0)
                      .toFixed(2)}
                </li>
                <li>
                  Total Close Fees:
                  {" $" +
                    closedPositions
                      .reduce((currentValue, position) => Number(currentValue) + Number(position.closeFeeInUsd), 0)
                      .toFixed(2)}
                </li>
              </ul>
            </div>
            <div className="details-action">
              <ul>
                <li>Status : {strategy.status}</li>
                <li>
                  {strategy.status === "Active" ? (
                    <button className="button primary" onClick={() => deactivateHandler(strategy)}>
                      Deactivate
                    </button>
                  ) : (
                    <button className="button primary" onClick={() => activateHandler(strategy)}>
                      Activate
                    </button>
                  )}
                </li>
                <li>
                  {strategy.status === "Active" ? (
                    strategy.doNotOpenNewPositions ? (
                      <button className="button primary" onClick={() => unshutdownHandler(strategy)}>
                        Un-shutdown
                      </button>
                    ) : (
                      <button className="button primary" onClick={() => shutdownHandler(strategy)}>
                        Shutdown
                      </button>
                    )
                  ) : null}
                </li>
                <li>
                  <Link to={"/strategy/edit/" + strategy.id}>
                    <button className="button primary">Edit</button>
                  </Link>
                </li>
                <li>
                  Created On : <Moment>{strategy.createdOn}</Moment>
                </li>
                <li>
                  Last Modified : <Moment>{strategy.lastModified}</Moment>
                </li>
                <li>
                  Open positions :{strategy.outstandingPositions} /{strategy.maxOpenPositions}
                </li>
              </ul>
              <ul>
                <li>
                  {strategy.doNotOpenNewPositions && strategy.status === "Active" ? (
                    <b>Strategy is shutting down</b>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h2>Outstanding Positions</h2>
            {openPositions && openPositions.length > 0 ? (
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>State</th>
                      <th>Exchange Name</th>
                      <th>Position Open</th>
                      <th>Open Quntity</th>
                      <th>Open Price</th>
                      <th>Open Fees</th>
                    </tr>
                  </thead>
                  <tbody>
                    {openPositions.map((position) => (
                      <tr key={position.id}>
                        <td> {openPositionRowCount++}</td>
                        <td>{position.state}</td>
                        <td>{position.cryptocurrencyExchangeName}</td>
                        <td>
                          <Moment>{position.openTimeStamp}</Moment>
                        </td>
                        <td>{position.openQuantity}</td>
                        <td>{Number(position.openPriceInUsd).toFixed(2)}</td>
                        <td>{Number(position.openFeeInUsd).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>No open positions</div>
            )}
          </div>
          <div>
            <h2>Closed Positions</h2>
            {closedPositions && closedPositions.length > 0 ? (
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Exchange Name</th>
                      <th>Position Open</th>
                      <th>Position Close</th>
                      <th>Open Quantity</th>
                      <th>Close Quantity</th>
                      <th>Open Price</th>
                      <th>Close Price</th>
                      <th>Open Fees</th>
                      <th>Close Fees</th>
                      <th>Profit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {closedPositions.map((position) => (
                      <tr key={position.id}>
                        <td> {closePositionRowCount++}</td>
                        <td>{position.cryptocurrencyExchangeName}</td>
                        <td>
                          <Moment>{position.openTimeStamp}</Moment>
                        </td>
                        <td>
                          <Moment>{position.closeTimeStamp}</Moment>
                        </td>
                        <td>{position.openQuantity}</td>
                        <td>{position.closeQuantity}</td>
                        <td>{Number(position.openPriceInUsd).toFixed(2)}</td>
                        <td>{Number(position.closePriceInUsd).toFixed(2)}</td>
                        <td>{Number(position.openFeeInUsd).toFixed(2)}</td>
                        <td>{Number(position.closeFeeInUsd).toFixed(2)}</td>
                        <td>
                          {(
                            Number(position.closePriceInUsd) * Number(position.closeQuantity) -
                            Number(position.openQuantity) * Number(position.openPriceInUsd) -
                            Number(position.openFeeInUsd) -
                            Number(position.closeFeeInUsd)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>No closed positions</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default StrategyDetailScreen;
