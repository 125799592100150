import {
  REPORT_SNAPSHOT_REQUEST,
  REPORT_SNAPSHOT_FAILURE,
  REPORT_SNAPSHOT_SUCCESS,
} from "../constants/reportConstants";

function reportSnapshotReducer(state = { report: {} }, action) {
  switch (action.type) {
    case REPORT_SNAPSHOT_REQUEST:
      return { loading: true };
    case REPORT_SNAPSHOT_SUCCESS:
      return { loading: false, success: true, report: action.payload };
    case REPORT_SNAPSHOT_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { reportSnapshotReducer };
