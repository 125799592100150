import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listExchanges } from "../actions/exchangeActions";

function ExchangesScreen(props) {
  const { exchanges } = useSelector((state) => state.exchangeList);
  const { userInfo } = useSelector((state) => state.userSignin);

  let rowCount = 1;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "PMDash - Exchanges";
    if (!userInfo) {
      props.history.push("/signin");
    }
    dispatch(listExchanges());
    return () => {};
  }, [userInfo]);

  return (
    <div className="content content-margined">
      <div className="portfolio-header">
        <h3>Exchanges</h3>
      </div>

      <div className="portfolio-list"></div>
      {exchanges ? (
        exchanges.length > 0 ? (
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Base URL</th>
                  <th>Minimum Order Size in $</th>
                </tr>
              </thead>
              <tbody>
                {exchanges.map((exchange) => (
                  <tr key={exchange.id}>
                    <td>{rowCount++}</td>
                    <td>{exchange.name}</td>
                    <td>{exchange.baseUrl}</td>
                    <td>{exchange.minimumOrderSizeInUsd}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>No Exchanges</div>
        )
      ) : (
        <div>
          <Link to="/signin">Log in</Link>
        </div>
      )}
    </div>
  );
}

export default ExchangesScreen;
