export const CURRENCY_BALANCE_REQUEST = "CURRENCY_BALANCE_REQUEST";
export const CURRENCY_BALANCE_SUCCESS = "CURRENCY_BALANCE_SUCCESS";
export const CURRENCY_BALANCE_FAILURE = "CURRENCY_BALANCE_FAILURE";

export const CURRENCY_TICKER_REQUEST = "CURRENCY_TICKER_REQUEST";
export const CURRENCY_TICKER_SUCCESS = "CURRENCY_TICKER_SUCCESS";
export const CURRENCY_TICKER_FAILURE = "CURRENCY_TICKER_FAILURE";

export const EXCHANGE_LIST_REQUEST = "EXCHANGE_LIST_REQUEST";
export const EXCHANGE_LIST_SUCCESS = "EXCHANGE_LIST_SUCCESS";
export const EXCHANGE_LIST_FAILURE = "EXCHANGE_LIST_FAILURE";




