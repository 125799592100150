import {
  CURRENCY_DETAILS_BY_SYMBOL_FAILURE,
  CURRENCY_DETAILS_BY_SYMBOL_REQUEST,
  CURRENCY_DETAILS_BY_SYMBOL_SUCCESS,
  CURRENCY_DETAILS_FAILURE,
  CURRENCY_DETAILS_REQUEST,
  CURRENCY_DETAILS_SUCCESS,
  CURRENCY_LIST_FAILURE,
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_SAVE_FAILURE,
  CURRENCY_SAVE_REQUEST,
  CURRENCY_SAVE_SUCCESS,
} from "../constants/currencyConstants";

function currencyListReducer(state = { currencies: [] }, action) {
  switch (action.type) {
    case CURRENCY_LIST_REQUEST:
      return { loading: true, currencies: [] };
    case CURRENCY_LIST_SUCCESS:
      return { loading: false, currencies: action.payload };
    case CURRENCY_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function currencyDetailsReducer(state = { currency: {} }, action) {
  switch (action.type) {
    case CURRENCY_DETAILS_REQUEST:
      return { loading: true };
    case CURRENCY_DETAILS_SUCCESS:
      return { loading: false, currency: action.payload };
    case CURRENCY_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function currencyDetailsBySymbolReducer(state = { currency: {} }, action) {
  switch (action.type) {
    case CURRENCY_DETAILS_BY_SYMBOL_REQUEST:
      return { loading: true };
    case CURRENCY_DETAILS_BY_SYMBOL_SUCCESS:
      return { loading: false, currencyBySymbol: action.payload };
    case CURRENCY_DETAILS_BY_SYMBOL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function currencySaveReducer(state = { currency: {} }, action) {
  switch (action.type) {
    case CURRENCY_SAVE_REQUEST:
      return { loading: true };
    case CURRENCY_SAVE_SUCCESS:
      return { loading: false, success: true, currency: action.payload };
    case CURRENCY_SAVE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { currencySaveReducer, currencyDetailsReducer, currencyListReducer ,currencyDetailsBySymbolReducer};
