import axios from "axios";
import {
  PORTFOLIO_LIST_FAILURE,
  PORTFOLIO_LIST_REQUEST,
  PORTFOLIO_LIST_SUCCESS,
  PORTFOLIO_SAVE_FAILURE,
  PORTFOLIO_SAVE_REQUEST,
  PORTFOLIO_SAVE_SUCCESS,
} from "../constants/portfolioConstants";

const listPortfolios = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_LIST_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/portfolio", {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: PORTFOLIO_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PORTFOLIO_LIST_FAILURE, payload: error.message });
  }
};

const savePortfolio = (portfolio) => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_SAVE_REQUEST, payload: portfolio });
    const {
      userSignin: { userInfo },
    } = getState();
    if (portfolio._id) {
      const { data } = await axios.put("/api/portfolio/" + portfolio._id, portfolio, {
        headers: {
          Authorization: "Bearer " + userInfo.token,
        },
      });
      dispatch({ type: PORTFOLIO_SAVE_SUCCESS, payload: data });
    } else {
      const { data } = await axios.post("/api/portfolio", portfolio, {
        headers: {
          Authorization: "Bearer " + userInfo.token,
        },
      });
      dispatch({ type: PORTFOLIO_SAVE_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({ type: PORTFOLIO_SAVE_FAILURE, payload: error.message });
  }
};

export { listPortfolios, savePortfolio };
