import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { detailsStrategy } from "../actions/strategyActions";
import { searchPositionsByStrategyId } from "../actions/positionActions";

function PositionDetailScreen(props) {
  const [numOfMaxPositions, set_num_of_max_open_positions] = useState(1);
  const strategyDetails = useSelector((state) => state.strategyDetails);
  const { strategy, loading, error } = strategyDetails;
  const { positions: strategyPositions } = useSelector((state) => state.positionSearchByStrategyId);
  let openPositions = strategyPositions.filter((position) => position.state === "Open" || position.state === "Pending");
  let closedPositions = strategyPositions.filter(
    (position) => position.state !== "Open" && position.state !== "Pending"
  );
  let rowCount = 1;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsStrategy(props.match.params.id));
    dispatch(searchPositionsByStrategyId(props.match.params.id));
    return () => {};
  }, []);

  const handleSetMaxPosition = () => {
    props.history.push("/strategyEdit/" + props.match.params.id + "?maxPosition=" + numOfMaxPositions);
  };

  return (
    <div>
      <div className="back-to-home">
        <Link to="/">Back to Home</Link>
      </div>
      {loading ? (
        <div>Loading..</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div>
          <div className="details">
            <div className="details-info">
              <ul>
                <li>
                  <h4>{strategy.name}</h4>
                </li>
                <li>Budget : ${strategy.budget}</li>
                <li>Balance : ${strategy.balance}</li>
                <li>
                  Version : {strategy.version}
                  <div>
                    <Link to={"/strategy/history/" + strategy.id}>View versions</Link>
                  </div>
                </li>
                <li>
                  Description:
                  <div>{strategy.description}</div>
                </li>
              </ul>
            </div>
            <div className="details-action">
              <ul>
                <li>Status : {strategy.status}</li>
                <li>Created on : {strategy.created_on}</li>
                <li>Number of open positions : {strategy.maxNumOfPositions}</li>
                <li>
                  Set Max Open Positions:
                  <select
                    value={numOfMaxPositions}
                    onChange={(e) => {
                      set_num_of_max_open_positions(e.target.value);
                    }}
                  >
                    {[...Array(strategy.maxNumOfPositions).keys()].map((x) => (
                      <option key={x + 1} value={x + 1}>
                        {x + 1}
                      </option>
                    ))}
                  </select>
                </li>
                <li>
                  {strategy.maxNumOfPositions > 0 ? (
                    <div>
                      <button onClick={handleSetMaxPosition} className="button">
                        Apply
                      </button>
                      <br />
                    </div>
                  ) : (
                    <div>No position to open.</div>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div>
            {openPositions ? (
              <div>
                <h2>Open and Pending Positions</h2>
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>State</th>
                      <th>Currency Name</th>
                      <th>Exchange Name</th>
                      <th>Position Open</th>
                      <th>Open Quntity</th>
                      <th>Open Price</th>
                      <th>Open Fees</th>
                    </tr>
                  </thead>
                  <tbody>
                    {openPositions.map((position) => (
                      <tr key={position._id}>
                        <td> {rowCount++}</td>
                        <td>{position.state}</td>
                        <td>{position.currencyName}</td>
                        <td>{position.cryptocurrencyExchangeName}</td>
                        <td>{position.openTimeStamp}</td>
                        <td>{position.openQuantity}</td>
                        <td>{position.openPriceInUsd}</td>
                        <td>{position.openFeeInUsd}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>No open positions</div>
            )}
          </div>
          <div>
            {closedPositions ? (
              <div>
                <h2>Closed Positions</h2>
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>State</th>
                      <th>Currency Name</th>
                      <th>Exchange Name</th>
                      <th>Position Open</th>
                      <th>Open Quntity</th>
                      <th>Open Price</th>
                      <th>Open Fees</th>
                      <th>Position Close</th>
                      <th>Close Quntity</th>
                      <th>Close Price</th>
                      <th>Close Fees</th>
                    </tr>
                  </thead>
                  <tbody>
                    {closedPositions.map((position) => (
                      <tr key={position._id}>
                        <td> {rowCount++}</td>
                        <td>{position.state}</td>
                        <td>{position.currencyName}</td>
                        <td>{position.cryptocurrencyExchangeName}</td>
                        <td>{position.openTimeStamp}</td>
                        <td>{position.openQuantity}</td>
                        <td>{position.openPriceInUsd}</td>
                        <td>{position.openFeeInUsd}</td>
                        <td>{position.closeTimeStamp}</td>
                        <td>{position.closeQuantity}</td>
                        <td>{position.closePriceInUsd}</td>
                        <td>{position.closeFeeInUsd}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>No closed positions</div>
            )}
          </div>
        </div>
      )}{" "}
    </div>
  );
}

export default PositionDetailScreen;
