import axios from "axios";
import {
  REPORT_SNAPSHOT_FAILURE,
  REPORT_SNAPSHOT_SUCCESS,
  REPORT_SNAPSHOT_REQUEST,
} from "../constants/reportConstants";


const reportSnapshot = () => async (dispatch, getState) => {
    try {
      dispatch({ type: REPORT_SNAPSHOT_REQUEST });
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await axios.get("/api/Report?normlizedTime=true&timeOffset=-8", {
        headers: {
          Authorization: "Bearer " + userInfo.token,
        },
      });
      dispatch({ type: REPORT_SNAPSHOT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: REPORT_SNAPSHOT_FAILURE, payload: error.message });
    }
  };


export { reportSnapshot };
