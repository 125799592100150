import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./store";
import axios from "axios";
import config from "./config";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
import "moment-timezone";

axios.defaults.baseURL = config.API_BASE_URL;

Moment.globalMoment = moment;
Moment.globalFormat = "YYYY-MM-DD HH:mm:ss";
//Moment.globalFormat = "DATETIME_LOCAL";
//Moment.globalTimezone = 'America/Los_Angeles';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location = "/signin";
    }
    return error;
  }
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
