import axios from "axios";
import {
  STRATEGY_LIST_REQUEST,
  STRATEGY_LIST_SUCCESS,
  STRATEGY_LIST_FAILURE,
  STRATEGY_DETAILS_REQUEST,
  STRATEGY_DETAILS_SUCCESS,
  STRATEGY_DETAILS_FAILURE,
  STRATEGY_DEACTIVATE_REQUEST,
  STRATEGY_DEACTIVATE_FAILURE,
  STRATEGY_DEACTIVATE_SUCCESS,
  STRATEGY_ACTIVATE_REQUEST,
  STRATEGY_ACTIVATE_SUCCESS,
  STRATEGY_ACTIVATE_FAILURE,
  STRATEGY_CREATE_REQUEST,
  STRATEGY_CREATE_SUCCESS,
  STRATEGY_UNSHUTDOWN_REQUEST,
  STRATEGY_SHUTDOWN_SUCCESS,
  STRATEGY_SHUTDOWN_FAILURE,
  STRATEGY_SHUTDOWN_REQUEST,
  STRATEGY_UNSHUTDOWN_SUCCESS,
  STRATEGY_UNSHUTDOWN_FAILURE,
  STRATEGY_CREATE_FAILURE,
  STRATEGY_UPDATE_FAILURE,
  STRATEGY_UPDATE_SUCCESS,
  STRATEGY_UPDATE_REQUEST,
  STRATEGY_HISTORIES_REQUEST,
  STRATEGY_HISTORIES_SUCCESS,
  STRATEGY_HISTORIES_FAILURE,
} from "../constants/strategyConstants";

var axiosInstance = axios.create({
  validateStatus: function (status) {
    return status < 400;
  },
});

const listStrategies =
  (currencySymbol = "", offset = 0, size = 0) =>
  async (dispatch, getState) => {
    dispatch({ type: STRATEGY_LIST_REQUEST });

    const {
      userSignin: { userInfo },
    } = getState();
    let url = "/api/strategy/paged?offset=" + offset + "&size=" + size;

    if (currencySymbol !== "") {
      url += "&currencySymbol=" + currencySymbol;
    }

    await axiosInstance
      .get(url, {
        headers: {
          Authorization: "Bearer " + userInfo.token,
        },
      })
      .then((response) => {
        dispatch({ type: STRATEGY_LIST_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: STRATEGY_LIST_FAILURE, payload: error.response.data.message });
      });
  };

const listStrategyHistory = (strategyId) => async (dispatch, getState) => {
  dispatch({ type: STRATEGY_HISTORIES_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();

  let url = "/api/strategy/" + strategyId + "/history";
  await axiosInstance
    .get(url, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    })
    .then((response) => {
      dispatch({ type: STRATEGY_HISTORIES_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: STRATEGY_HISTORIES_FAILURE, payload: error.response.data.message });
    });
};

const detailsStrategy = (strategyId) => async (dispatch, getState) => {
  dispatch({ type: STRATEGY_DETAILS_REQUEST, payload: strategyId });

  const {
    userSignin: { userInfo },
  } = getState();

  await axiosInstance
    .get("/api/strategy/" + strategyId, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    })
    .then((response) => {
      dispatch({ type: STRATEGY_DETAILS_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: STRATEGY_DETAILS_FAILURE, payload: error.response.data.message });
    });
};

const deactivateStrategy = (strategyId) => async (dispatch, getState) => {
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({ type: STRATEGY_DEACTIVATE_REQUEST, payload: strategyId });
    console.log(strategyId);
    const { data } = await axios.get("/api/Strategy/deactivate/" + strategyId, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });
    dispatch({ type: STRATEGY_DEACTIVATE_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: STRATEGY_DEACTIVATE_FAILURE, payload: error.message });
  }
};

const activateStrategy = (strategyId) => async (dispatch, getState) => {
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({ type: STRATEGY_ACTIVATE_REQUEST, payload: strategyId });
    const { data } = await axios.get("/api/Strategy/activate/" + strategyId, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });
    dispatch({ type: STRATEGY_ACTIVATE_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: STRATEGY_ACTIVATE_FAILURE, payload: error.message });
  }
};

const shutdownStrategy = (strategyId) => async (dispatch, getState) => {
  dispatch({ type: STRATEGY_SHUTDOWN_REQUEST, payload: strategyId });

  const {
    userSignin: { userInfo },
  } = getState();

  await axiosInstance
    .get("/api/Strategy/shutdown/" + strategyId, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    })
    .then((response) => {
      dispatch({ type: STRATEGY_SHUTDOWN_SUCCESS, payload: response.data, success: true });
    })
    .catch((error) => {
      dispatch({ type: STRATEGY_SHUTDOWN_FAILURE, payload: error.response.data.message });
    });
};

const unshutdownStrategy = (strategyId) => async (dispatch, getState) => {
  dispatch({ type: STRATEGY_UNSHUTDOWN_REQUEST, payload: strategyId });

  const {
    userSignin: { userInfo },
  } = getState();

  await axiosInstance
    .get("/api/Strategy/unshutdown/" + strategyId, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    })
    .then((response) => {
      dispatch({ type: STRATEGY_UNSHUTDOWN_SUCCESS, payload: response.data, success: true });
    })
    .catch((error) => {
      dispatch({ type: STRATEGY_UNSHUTDOWN_FAILURE, payload: error.response.data.message });
    });
};

const createStrategy = (strategy) => async (dispatch, getState) => {
  dispatch({ type: STRATEGY_CREATE_REQUEST, payload: strategy });

  const {
    userSignin: { userInfo },
  } = getState();

  await axiosInstance
    .post("/api/Strategy", strategy, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    })
    .then((response) => {
      dispatch({ type: STRATEGY_CREATE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: STRATEGY_CREATE_FAILURE, payload: error.response.data.error });
    });
};

const updateStrategy = (strategy) => async (dispatch, getState) => {
  dispatch({ type: STRATEGY_UPDATE_REQUEST, payload: strategy });

  const {
    userSignin: { userInfo },
  } = getState();
  console.log(strategy);

  await axiosInstance.put("/api/Strategy/" + strategy.id, strategy, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    })
    .then((response) => {
      console.log("in reposnse");
      dispatch({ type: STRATEGY_UPDATE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: STRATEGY_UPDATE_FAILURE, payload: error.response.data.error });
    });
};

export {
  listStrategies,
  detailsStrategy,
  deactivateStrategy,
  activateStrategy,
  createStrategy,
  shutdownStrategy,
  unshutdownStrategy,
  updateStrategy,
  listStrategyHistory,
};
