import {
  POSITION_DETAILS_FAILURE,
  POSITION_DETAILS_REQUEST,
  POSITION_DETAILS_SUCCESS,
  POSITION_LIST_FAILURE,
  POSITION_LIST_REQUEST,
  POSITION_LIST_SUCCESS,
  POSITION_OPEN_LIST_FAILURE,
  POSITION_OPEN_LIST_REQUEST,
  POSITION_OPEN_LIST_SUCCESS,
  POSITION_SAVE_FAILURE,
  POSITION_SAVE_REQUEST,
  POSITION_SAVE_SUCCESS,
  POSITION_SEARCH_FAILURE,
  POSITION_SEARCH_REQUEST,
  POSITION_SEARCH_SUCCESS,
} from "../constants/positionConstants";

function positionListReducer(state = { positions: [] }, action) {
  switch (action.type) {
    case POSITION_LIST_REQUEST:
      return { loading: true, positions: [] };
    case POSITION_LIST_SUCCESS:
      return { loading: false, positions: action.payload };
    case POSITION_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function positionOpenListReducer(state = { positions: [] }, action) {
  switch (action.type) {
    case POSITION_OPEN_LIST_REQUEST:
      return { loading: true, positions: [] };
    case POSITION_OPEN_LIST_SUCCESS:
      return { loading: false, positions: action.payload };
    case POSITION_OPEN_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function positionDetailsReducer(state = { position: {} }, action) {
  switch (action.type) {
    case POSITION_DETAILS_REQUEST:
      return { loading: true };
    case POSITION_DETAILS_SUCCESS:
      return { loading: false, position: action.payload };
    case POSITION_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function positionSearchByStrategyIdReducer(state = { positions: [] }, action) {
  switch (action.type) {
    case POSITION_SEARCH_REQUEST:
      return { loading: true, positions: [] };
    case POSITION_SEARCH_SUCCESS:
      return { loading: false, positions: action.payload };
    case POSITION_SEARCH_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function positionSearchByCurrencyReducer(state = { positions: [] }, action) {
  switch (action.type) {
    case POSITION_SEARCH_REQUEST:
      return { loading: true, positions: [] };
    case POSITION_SEARCH_SUCCESS:
      return { loading: false, positions: action.payload };
    case POSITION_SEARCH_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function positionSaveReducer(state = { position: {} }, action) {
  switch (action.type) {
    case POSITION_SAVE_REQUEST:
      return { loading: true };
    case POSITION_SAVE_SUCCESS:
      return { loading: false, success: true, position: action.payload };
    case POSITION_SAVE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  positionSaveReducer,
  positionDetailsReducer,
  positionListReducer,
  positionSearchByStrategyIdReducer,
  positionOpenListReducer,
  positionSearchByCurrencyReducer,
};
