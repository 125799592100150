import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listOpenPositions } from "../actions/positionActions";
import ReactPaginate from "react-paginate";

function PositionsScreen(props) {
  const { positions, loading } = useSelector((state) => state.positionList);
  const { userInfo } = useSelector((state) => state.userSignin);

  // Paging
  let [rowCount, setRowCount] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "PMDash - Positions";
    if (!userInfo) {
      props.history.push("/signin?redirect=positions");
    }
    dispatch(listOpenPositions(itemOffset, itemsPerPage));
    setPageCount(positions.totalNumberOfPages);
    return () => {};
  }, [currentPage]);

  const handlePageClick = (e) => {
    const newOffset = e.selected * itemsPerPage;
    setItemOffset(newOffset);
    setRowCount(newOffset + 1);
    setCurrentPage(e.selected);
  };

  const search = (e) => {
    console.log(`Search pressed`);
  };

  return (
    <div className="content content-margined">
      <div className="position-header">
        <h3>Positions</h3>
      </div>
      <div className="input-group">
        <h4>Pick Position Status</h4>
        <div>
          <label>Open</label>
          <input type="checkbox" value="Open" defaultChecked="true" title="Open" />
          <label>Copmleted</label>
          <input type="checkbox" value="Copmleted" title="Copmleted" />
          <label>PendingSell</label>
          <input type="checkbox" value="PendingSell" title="PendingSell" />
          <label>PendingBuy</label>
          <input type="checkbox" value="PendingBuy" title="PendingBuy" />
        </div>
        <input type="buttun" value="Search" className="button secondary" onClick={search} />
      </div>
      <div className="position-list"></div>
      {loading ? (
        <div>Loading Positions</div>
      ) : positions && positions.results ? (
        positions.results.length > 0 ? (
          <div>
            <label>
              Showing results - {currentPage * itemsPerPage + 1} to{" "}
              {currentPage * itemsPerPage + positions.results.length} from {positions.totalNumberOfRecords}
            </label>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>State</th>
                  <th>Currency Name</th>
                  <th>Strategy</th>
                  <th>Exchange Name</th>
                  <th>Position Open</th>
                  <th>Open Quntity</th>
                  <th>Open Price</th>
                  <th>Open Fees</th>
                  <th>Position Close</th>
                  <th>Close Quntity</th>
                  <th>Close Price</th>
                  <th>Close Fees</th>
                </tr>
              </thead>
              <tbody>
                {positions.results.map((position) => (
                  <tr key={position.id}>
                    <td> {rowCount++}</td>
                    <td>{position.state}</td>
                    <td>
                      <Link to={"/currency/symbol/" + position.currencySymbol}> {position.currencyName}</Link>
                    </td>
                    <td>
                      <Link to={"/strategy/id/" + position.strategyId}>{position.strategyName}</Link>
                    </td>
                    <td>{position.cryptocurrencyExchangeName}</td>
                    <td>
                      <Moment>{position.openTimeStamp}</Moment>
                    </td>
                    <td>{position.openQuantity}</td>
                    <td>{Number(position.openPriceInUsd).toFixed(2)}</td>
                    <td>{Number(position.openFeeInUsd).toFixed(2)}</td>
                    <td>{position.closeTimeStamp ? <Moment>{position.closeTimeStamp}</Moment> : ""}</td>
                    <td>{position.closeQuantity}</td>
                    <td>{Number(position.closePriceInUsd)?.toFixed(2)}</td>
                    <td>{Number(position.closeFeeInUsd)?.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"< previous"}
              nextLabel={"next >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              forcePage={currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        ) : null
      ) : null}
    </div>
  );
}

export default PositionsScreen;
