import {
  COOLDOWN_POLICY_LIST_REQUEST,
  COOLDOWN_POLICY_LIST_SUCCESS,
  COOLDOWN_POLICY_LIST_FAILURE,
  MARKET_ELASTICITY_POLICY_LIST_REQUEST,
  MARKET_ELASTICITY_POLICY_LIST_SUCCESS,
  MARKET_ELASTICITY_POLICY_LIST_FAILURE,
} from "../constants/policyConstants";

function cooldownPolicyListReducer(state = { cooldownPolicies: [] }, action) {
  switch (action.type) {
    case COOLDOWN_POLICY_LIST_REQUEST:
      return { loading: true, cooldownPolicies: [] };
    case COOLDOWN_POLICY_LIST_SUCCESS:
      return { loading: false, cooldownPolicies: action.payload };
    case COOLDOWN_POLICY_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function marketElasticityPoliciesListReducer(state = { marketElasticityPoliciesList: [] }, action) {
  switch (action.type) {
    case MARKET_ELASTICITY_POLICY_LIST_REQUEST:
      return { loading: true, marketElasticityPolicies: [] };
    case MARKET_ELASTICITY_POLICY_LIST_SUCCESS:
      return { loading: false, marketElasticityPolicies: action.payload };
    case MARKET_ELASTICITY_POLICY_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { cooldownPolicyListReducer, marketElasticityPoliciesListReducer };
