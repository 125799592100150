import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  listStrategies,
  deactivateStrategy,
  activateStrategy,
  shutdownStrategy,
  unshutdownStrategy,
} from "../actions/strategyActions";
import ReactPaginate from "react-paginate";

function StrategiesScreen(props) {
  const { strategies, loading: loadingList } = useSelector((state) => state.strategyList);
  const strategyDeactivate = useSelector((state) => state.strategyDeactivate);
  const { success: successDeactivate } = strategyDeactivate;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  // Paging
  let [rowCount, setRowCount] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "PMDash - Strategies";
    if (!userInfo) {
      props.history.push("/signin?redirect=strategies");
    }
    dispatch(listStrategies('', itemOffset, itemsPerPage));
    setPageCount(strategies.totalNumberOfPages);
    return () => {};
  }, [currentPage, successDeactivate]);

  const handlePageClick = (e) => {
    const newOffset = e.selected * itemsPerPage;
    console.log(`User requested page number ${e.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
    setRowCount(newOffset + 1);
    setCurrentPage(e.selected);
    console.log(`Returned ${strategies.results.length} out of ${strategies.totalNumberOfRecords}.`);
  };

  const deactivateHandler = (strategy) => {
    dispatch(deactivateStrategy(strategy.id));
  };

  const activateHandler = (strategy) => {
    dispatch(activateStrategy(strategy.id));
  };

  const shutdownHandler = (strategy) => {
    dispatch(shutdownStrategy(strategy.id));
  };

  const unshutdownHandler = (strategy) => {
    dispatch(unshutdownStrategy(strategy.id));
  };

  return (
    <div className="content content-margined">
      <h3>Strategies</h3>
      <div className="strategy-header">
        <button className="button primary">
          <Link to="/strategy/new">Create New Strategy</Link>
        </button>
      </div>
      <br />
      <div className="strategy-list">
        {loadingList ? (
          <div>Loading strategies </div>
        ) : strategies && strategies.results? (
          <div>
            <label>
              Showing results - {currentPage * itemsPerPage + 1} to{" "}
              {currentPage * itemsPerPage + strategies.results.length} from {strategies.totalNumberOfRecords}
            </label>
            <table className="table">
              <thead>
                <tr>
                  <td>#</td>
                  <th>Name</th>
                  <th>Currency</th>
                  <th>Exchange Name</th>
                  <th>Positions</th>
                  <th>Budget / Balance</th>
                  <th>Original Budget</th>
                  <th>Status</th>
                  <th>Edit</th>
                  <th>Active</th>
                  <th>Shutting Down</th>
                </tr>
              </thead>
              <tbody>
                {strategies.results.map((strategy) => (
                  <tr key={strategy.id}>
                    <td>{rowCount++}</td>
                    <td>
                      <Link to={"/strategy/id/" + strategy.id}>{strategy.name}</Link>
                    </td>
                    <td>
                      <Link to={"/currency/symbol/" + strategy.currencySymbol}>
                        {strategy.currencyName} ({strategy.currencySymbol})
                      </Link>
                    </td>
                    <td>{strategy.cryptocurrencyExchangeName}</td>
                    <td align="center">
                      {strategy.outstandingPositions}/{strategy.maxOpenPositions}
                    </td>
                    <td align="center">
                      {Number(strategy.budget).toFixed(2)} / {Number(strategy.balance).toFixed(2)}
                    </td>
                    <td align="center">{Number(strategy.originalBudget).toFixed(2)}</td>
                    <td>
                      {strategy.status !== "Deactivated"
                        ? strategy.doNotOpenNewPositions
                          ? "Shutting Down"
                          : strategy.status
                        : strategy.status}
                    </td>
                    <td>
                      <div>
                        <Link to={"/strategy/edit/" + strategy.id}>
                          <button className="button">Edit</button>
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div>
                        {strategy.status === "Active" ? (
                          <button className="button" onClick={() => deactivateHandler(strategy)}>
                            Deactivate
                          </button>
                        ) : (
                          <button className="button" onClick={() => activateHandler(strategy)}>
                            Activate
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>
                        {strategy.doNotOpenNewPositions ? (
                          <button className="button" onClick={() => unshutdownHandler(strategy)}>
                            Un-shutdown
                          </button>
                        ) : (
                          <button className="button" onClick={() => shutdownHandler(strategy)}>
                            Shutdown
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"< previous"}
              nextLabel={"next >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              forcePage={currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default StrategiesScreen;
