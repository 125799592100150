import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../actions/userActions";

function SigninScreen(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { loading, error } = userSignin;
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "PMDash - Sign In";
    return () => {};
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password, username));
  };

  return (
    <div className="form">
      <form onSubmit={submitHandler}>
        <ul className="form-container">
          <li>
            <h2>Login</h2>
          </li>
          <li>
            {loading && <div>Loading</div>}
            {error && <div>{error}</div>}
          </li>
          <li>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)}></input>
          </li>
          <li>
            <label htmlFor="username">Username</label>
            <input type="username" name="username" id="username" onChange={(e) => setUsername(e.target.value)}></input>
          </li>
          <li>
            <label htmlFor="password">Password</label>
            <input type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)}></input>
          </li>
          <li>
            <button type="submit" className="button primary">
              Login
            </button>
          </li>
        </ul>
      </form>
    </div>
  );
}

export default SigninScreen;
