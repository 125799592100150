import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listPortfolios, savePortfolio } from "../actions/portfolioActions";

function PortfoliosScreen(props) {
  const [modelVisible, setModelVisiable] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const { portfolios } = useSelector((state) => state.portfolioList);
  const portfolioSave = useSelector((state) => state.portfolioSave);
  const { loading: loadingSave, success: successSave, error: errorSave } = portfolioSave;

  let rowCount = 1;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "PMDash - Portfolios";
    if (successSave) {
      setModelVisiable(false);
    }
    dispatch(listPortfolios());
    return () => {};
  }, [successSave]);

  const openModel = (portfolio) => {
    setModelVisiable(true);
    setId(portfolio._id);
    setName(portfolio.name);
    setSymbol(portfolio.symbol);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePortfolio({ _id: id, name, symbol }));
  };

  return (
    <div className="content content-margined">
      <div className="portfolio-header">
        <h3>Portfolios</h3>
      </div>
      {modelVisible && (
        <div className="form">
          <form onSubmit={submitHandler}>
            <ul className="form-container">
              <li>
                <h2>Create Portfolio</h2>
              </li>
              <li>
                {loadingSave && <div>Loading</div>}
                {errorSave && <div>{errorSave}</div>}
              </li>
              <li>
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)}></input>
              </li>
              <li>
                <label htmlFor="symbol">Symbol</label>
                <input
                  type="text"
                  name="symbol"
                  id="symbol"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                ></input>
              </li>
              <li>
                <button type="submit" className="button primary">
                  {id ? "Update" : "Create"}
                </button>
              </li>
              <li>
                <button type="button" onClick={() => setModelVisiable(false)} className="button secondary">
                  Back
                </button>
              </li>
            </ul>
          </form>
        </div>
      )}
      <div className="portfolio-list"></div>
      {portfolios ? (
        portfolios.length > 0 ? (
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th># of Stratgies</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {portfolios.map((portfolio) => (
                  <tr key={portfolio.id}>
                    <td>{rowCount++}</td>
                    <td>{portfolio.name}</td>
                    <td>{portfolio.numOfStratgies}</td>
                    <td>
                      <button className="button" onClick={() => openModel(portfolio)}>
                        Edit
                      </button>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="portfolio-header">
              <button className="button primary" onClick={() => openModel({})}>
                Create Portfolio
              </button>
            </div>
          </div>
        ) : (
          <div>No Portfolios</div>
        )
      ) : (
        <div>
          <Link to="/signin">Log in</Link>
        </div>
      )}
    </div>
  );
}

export default PortfoliosScreen;
