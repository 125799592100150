import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { searchPositionsByCurrency } from "../actions/positionActions";
import Moment from "react-moment";
import { detailsCurrencyBySymbol } from "../actions/currencyActions";
import { listStrategies } from "../actions/strategyActions";
import { tickerCurrencyExchange } from "../actions/exchangeActions";

function CurrencyDetailScreen(props) {
  const currencyDetailsBySymbol = useSelector((state) => state.currencyDetailsBySymbol);
  const { currencyBySymbol: currency, loading, error } = currencyDetailsBySymbol;
  const { strategies, strategiesLoading, strategiesError } = useSelector((state) => state.strategyList);
  const { positions: strategyPositions } = useSelector((state) => state.positionSearchByCurrency);

  const { currencyTicker } = useSelector((state) => state.exchangeTicker);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  let openPositions = strategyPositions?.filter(
    (position) => position.state === "Open" || position.state === "PendingSell" || position.state === "PendingBuy"
  );
  let closedPositions = strategyPositions?.filter(
    (position) => position.state !== "Open" && position.state !== "PendingSell" && position.state !== "PendingBuy"
  );

  let strategyRowCount = 1,
    settingRowCount = 1,
    openPositionRowCount = 1,
    closePositionRowCount = 1;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "PMDash - Currency Details";

    if (!userInfo) {
      props.history.push("/signin?redirect=currencies");
    }

    dispatch(detailsCurrencyBySymbol(props.match.params.symbol));
    dispatch(listStrategies(props.match.params.symbol));
    dispatch(searchPositionsByCurrency(props.match.params.symbol));
    dispatch(tickerCurrencyExchange(props.match.params.symbol));
    return () => {};
  }, []);

  const getGainedQuantity = () => {
    let totalGainedQuantity = closedPositions.reduce(
      (currentValue, position) => Number(currentValue) + Number(position.openQuantity) - Number(position.closeQuantity),
      0
    );
    return Number(totalGainedQuantity).toFixed(2);
  };

  const getHeldQuantity = () => {
    return Number(
      openPositions.reduce((currentValue, position) => Number(currentValue) + Number(position.openQuantity), 0)
    ).toFixed(2);
  };

  return (
    <div>
      <div className="back-to-home">
        <Link to="/">Home</Link>/<Link to="/currencies">Currencies</Link>
      </div>
      <div>
        {loading ? (
          <div>Loading..</div>
        ) : error ? (
          <div>{error}</div>
        ) : currency ? (
          <div>
            <div className="details">
              <div className="details-info">
                <ul>
                  <li>
                    <h3>{currency.name}</h3>
                  </li>
                  <li>Symbol: {currency.symbol}</li>
                  <li>Currenct Price: {currencyTicker ? "$" + currencyTicker.close : "NA"}</li>
                  <li>
                    Version: {currency.version}
                    <div>
                      <Link to={"/currency/history/" + currency.id}>View versions</Link>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="details-info">
                <ul>
                  <li>
                    <h3>Profits and Fees</h3>
                  </li>
                  <li>
                    Held in Orders: {getHeldQuantity() + " " + currency.symbol} (currently worth -
                    {currencyTicker ? " $" + (getHeldQuantity() * currencyTicker.close).toFixed(2) : " NA"})
                  </li>
                  <li>
                    Total Quantity Gained: {getGainedQuantity() + " " + currency.symbol} (currently worth -
                    {currencyTicker ? " $" + (getGainedQuantity() * currencyTicker.close).toFixed(2) : " NA"})
                  </li>
                  <li>
                    Total Profit:
                    {" $" +
                      closedPositions
                        .reduce(
                          (currentValue, position) =>
                            currentValue +
                            (position.closeExecutedValueInUsd -
                              position.openExecutedValueInUsd -
                              position.closeFeeInUsd -
                              position.openFeeInUsd),
                          0
                        )
                        .toFixed(2)}
                  </li>
                  <li>
                    Total Open Fees:
                    {" $" +
                      closedPositions
                        .reduce((currentValue, position) => Number(currentValue) + Number(position.openFeeInUsd), 0)
                        .toFixed(2)}
                  </li>
                  <li>
                    Total Close Fees:
                    {" $" +
                      closedPositions
                        .reduce((currentValue, position) => Number(currentValue) + Number(position.closeFeeInUsd), 0)
                        .toFixed(2)}
                  </li>
                </ul>
              </div>
              <div className="details-action">
                <ul>
                  <li>
                    Created On: <Moment>{currency.createdOn}</Moment>
                  </li>
                  <li>
                    Last Modified: <Moment>{currency.lastModified}</Moment>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h4>Currency Exchange Settings</h4>
              {currency.currencyExchangeAccuracies.length > 0 ? (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <td>#</td>
                        <th>Exhcnage Name</th>
                        <th>Order Amount Accurecy</th>
                        <th>Ticker Amount Accurecy</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currency.currencyExchangeAccuracies.map((setting) => (
                        <tr key={setting.id}>
                          <td>{settingRowCount++}</td>
                          <td>{setting.cryptocurrencyExchangeName}</td>
                          <td>{setting.orderAmountAccuracy}</td>
                          <td>{setting.tickerAmountAccuracy}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No Exchange Settings</div>
              )}
            </div>
          </div>
        ) : (
          <div>Currency not found</div>
        )}
      </div>
      <div>
        <h2>Strategies</h2>
        {strategiesLoading ? (
          <div>Loading Strategies..</div>
        ) : strategiesError ? (
          <div>{strategiesError}</div>
        ) : strategies && strategies.results && strategies.results.length > 0 ? (
          <div className="details">
            <table className="table">
              <thead>
                <tr>
                  <td>#</td>
                  <th>Name</th>
                  <th>Exchange Name</th>
                  <th>Budget / Balance</th>
                  <th>Original Budget</th>
                  <th>Positions</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {strategies.results.map((strategy) => (
                  <tr key={strategy.id}>
                    <td>{strategyRowCount++}</td>
                    <td>
                      <Link to={"/strategy/id/" + strategy.id}>{strategy.name}</Link>
                    </td>
                    <td>{strategy.cryptocurrencyExchangeName}</td>
                    <td>
                      {Number(strategy.budget).toFixed(2)} / {Number(strategy.balance).toFixed(2)}
                    </td>
                    <td>{Number(strategy.originalBudget).toFixed(2)}</td>
                    <td>
                      {strategy.outstandingPositions}/{strategy.maxOpenPositions}
                    </td>
                    <td>
                      {strategy.status !== "Deactivated"
                        ? strategy.doNotOpenNewPositions
                          ? "Shutting Down"
                          : strategy.status
                        : strategy.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>No Strategies Found</div>
        )}
      </div>
      <div>
        <h2>Outstanding Positions</h2>
        {openPositions && openPositions.length > 0 ? (
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Strategy Name</th>
                  <th>State</th>
                  <th>Exchange Name</th>
                  <th>Position Open</th>
                  <th>Open Quantity</th>
                  <th>Open Price</th>
                  <th>Open Fees</th>
                </tr>
              </thead>
              <tbody>
                {openPositions.map((position) => (
                  <tr key={position.id}>
                    <td> {openPositionRowCount++}</td>
                    <td>
                      <Link to={"/strategy/id/" + position.strategyId}>{position.strategyName}</Link>
                    </td>
                    <td>{position.state}</td>
                    <td>{position.cryptocurrencyExchangeName}</td>
                    <td>
                      <Moment>{position.openTimeStamp}</Moment>
                    </td>
                    <td>{position.openQuantity}</td>
                    <td>{Number(position.openPriceInUsd).toFixed(2)}</td>
                    <td>{Number(position.openFeeInUsd).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>No open positions</div>
        )}
      </div>
      <div>
        <h2>Closed Positions</h2>
        {closedPositions && closedPositions.length > 0 ? (
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Strategy Name</th>
                  <th>Exchange Name</th>
                  <th>Position Open</th>
                  <th>Position Close</th>
                  <th>Open Quantity</th>
                  <th>Close Quantity</th>
                  <th>Open Price</th>
                  <th>Close Price</th>
                  <th>Open Fees</th>
                  <th>Close Fees</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                {closedPositions.map((position) => (
                  <tr key={position.id}>
                    <td> {closePositionRowCount++}</td>
                    <td>
                      <Link to={"/strategy/id/" + position.strategyId}>{position.strategyName}</Link>
                    </td>
                    <td>{position.cryptocurrencyExchangeName}</td>
                    <td>
                      <Moment>{position.openTimeStamp}</Moment>
                    </td>
                    <td>
                      <Moment>{position.closeTimeStamp}</Moment>
                    </td>
                    <td>{position.openQuantity}</td>
                    <td>{position.closeQuantity}</td>
                    <td>{Number(position.openPriceInUsd).toFixed(2)}</td>
                    <td>{Number(position.closePriceInUsd).toFixed(2)}</td>
                    <td>{Number(position.openFeeInUsd).toFixed(2)}</td>
                    <td>{Number(position.closeFeeInUsd).toFixed(2)}</td>
                    <td>
                      {Number(
                        position.closeExecutedValueInUsd -
                          position.openExecutedValueInUsd -
                          position.openFeeInUsd -
                          position.closeFeeInUsd
                      ).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>No closed positions</div>
        )}
      </div>
    </div>
  );
}

export default CurrencyDetailScreen;
