import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { testNotification } from "../actions/notificationActions";

function NotificationScreen(props) {
  const [messageText, setMessageText] = useState("");
  const { userInfo } = useSelector((state) => state.userSignin);
  const notificationTest = useSelector((state) => state.testNotification);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "PMDash - Notifications";
    if (!userInfo) {
      props.history.push("/signin");
    }
    return () => {};
  }, [userInfo]);

  const testNotificationHandler = (e) => {
    dispatch(testNotification(messageText));
  };

  return (
    <div className="content content-margined">
      <div className="portfolio-header">
        <h2>Notifications</h2>
      </div>
      <div>
        <h3>Notification Tester</h3>
        <label htmlFor="messageText">Message Text (Optional)</label>
        <input
          type="text"
          name="messageText"
          id="messageText"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        ></input>
        <button className="button" onClick={() => testNotificationHandler()}>
          Send Test Notification
        </button>
      </div>
    </div>
  );
}

export default NotificationScreen;
