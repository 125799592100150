import React from "react";
import { useSelector } from "react-redux";
import "./App.css";
import { BrowserRouter, Route, Link } from "react-router-dom";
import CurrenciesScreen from "./screens/CurrenciesScreen";
import SigninScreen from "./screens/SigninScreen";
import StrategiesScreen from "./screens/StrategiesScreen";
import PortfoliosScreen from "./screens/PortfoliosScreen";
import PositionsScreen from "./screens/PositionsScreen";
import HomeScreen from "./screens/HomeScreen";
import StrategyDetailScreen from "./screens/StrategyDetailScreen";
import PositionDetailScreen from "./screens/PositionDetailScreen";
import ReportsScreen from "./screens/ReportsScreen";
import CurrencyDetailScreen from "./screens/CurrencyDetailScreen";
import StrategyCreateScreen from "./screens/StrategyCreateScreen";
import StrategyEditScreen from "./screens/StrategyEditScreen";
import { List } from "semantic-ui-react";
import NotificationScreen from "./screens/NotificationScreen";
import ExchangesScreen from "./screens/ExchangesScreen";
import StrategyHistoriesScreen from "./screens/StrategyHistoriesScreen";

function App() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const openMenu = () => {
    document.querySelector(".sidebar").classList.add("open");
  };
  const closeMenu = () => {
    document.querySelector(".sidebar").classList.remove("open");
  };

  return (
    <BrowserRouter>
      <div className="grid-container">
        <header className="header">
          <div className="brand">
            <button onClick={openMenu}>&#9776;</button>
            <Link to="/">Prime Materia Dashboard</Link>
          </div>
          <div className="header-links">
            <a href="status.html">Status</a>
            {userInfo ? <Link to="/profile">{userInfo.username}</Link> : <Link to="/signin/">Signin</Link>}
          </div>
        </header>
        <aside className="sidebar">
          <h1>Categories</h1>
          <button className="sidebar-close-button" onClick={closeMenu}>
            x
          </button>
          <List as="ul">
            <List.Item as="li">
              <h2>
                <Link to="/portfolios" onClick={closeMenu}>
                  Portfolios
                </Link>
              </h2>
            </List.Item>
            <List.Item as="li">
              <h2>
                <Link to="/strategies" onClick={closeMenu}>
                  Strategies
                </Link>
              </h2>
              <List.List as="ul">
                <List.Item as="li">
                  <h3>
                    <Link to="/strategy/new" onClick={closeMenu}>
                      Create new strategy
                    </Link>
                  </h3>
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item as="li">
              <h2>
                <Link to="/currencies" onClick={closeMenu}>
                  Currencies
                </Link>
              </h2>
            </List.Item>
            <List.Item as="li">
              <h2>
                <Link to="/positions" onClick={closeMenu}>
                  Positions
                </Link>
              </h2>
            </List.Item>
            <List.Item as="li">
              <h2>
                <Link to="/exchanges" onClick={closeMenu}>
                  Exchanges
                </Link>
              </h2>
            </List.Item>
            <List.Item as="li">
              <h2>
                <Link to="/reports" onClick={closeMenu}>
                  Reports
                </Link>
              </h2>
            </List.Item>
            <List.Item as="li">
              <h2>
                <Link to="/notifications" onClick={closeMenu}>
                  Notifications
                </Link>
              </h2>
            </List.Item>
          </List>
        </aside>
        <main className="main">
          <div className="content">
            <Route path="/currencies" component={CurrenciesScreen} />
            <Route path="/signin" component={SigninScreen} />
            <Route path="/strategies" component={StrategiesScreen} />
            <Route path="/strategy/new" component={StrategyCreateScreen} />
            <Route path="/strategy/edit/:id" component={StrategyEditScreen} />
            <Route path="/strategy/history/:id" component={StrategyHistoriesScreen} />
            <Route path="/portfolios" component={PortfoliosScreen} />
            <Route path="/positions" component={PositionsScreen} />
            <Route path="/" exact={true} component={HomeScreen} />
            <Route path="/strategy/id/:id" component={StrategyDetailScreen} />
            <Route path="/currency/symbol/:symbol" component={CurrencyDetailScreen} />
            <Route path="/position/id/:id" component={PositionDetailScreen} />
            <Route path="/reports" component={ReportsScreen} />
            <Route path="/notifications" component={NotificationScreen} />
            <Route path="/exchanges" component={ExchangesScreen} />
          </div>
        </main>
        <footer className="footer">All rights reserved.</footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
