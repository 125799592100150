import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listPortfolios } from "../actions/portfolioActions";
import { listExchanges } from "../actions/exchangeActions";
import { listCurrencies } from "../actions/currencyActions";
import { listCooldownPolicies, listMarketElasticityPolicies } from "../actions/policyActions";
import { detailsStrategy, updateStrategy } from "../actions/strategyActions";

function StrategyEditScreen(props) {
  let [strategyName, setStrategyName] = useState("");
  let [description, setDescription] = useState("");
  let [maxNumOfPositions, setMaxNumOfPositions] = useState("");
  let [nickname, setNickname] = useState("");
  let [stablecoin, setStablecoin] = useState("");
  let [budget, setBudget] = useState("");
  let [orderType, setOrderType] = useState("");
  let [portfolioName, setPortfolioName] = useState("");
  let [positionSize, setPositionSize] = useState("");
  let [sellSpikePercent, setSellSpikePercent] = useState("");
  let [buyDropPercent, setBuyDropPercent] = useState("");
  let [buyDropThresholdInPercent, setBuyDropThresholdInPercent] = useState("");
  let [reinvestProfitPercentage, setReinvestProfitPercentage] = useState("");
  let [exchangeId, setExchangeId] = useState("");
  let [currencySymbol, setCurrencySymbol] = useState("");
  let [cooldownIntervalInMinutes, setCooldownIntervalInMinutes] = useState("");
  let [cooldownPolicyId, setCooldownPolicyId] = useState("");
  let [marketElasticityPolicyId, setMarketElasticityPolicyId] = useState("");
  let [sellPositionSizePercentage, setSellPositionSizePercentage] = useState("");
  let [positionSizeType, setPositionSizeType] = useState("");
  let [balance, setBalance] = useState("");
  const { portfolios } = useSelector((state) => state.portfolioList);
  const { exchanges } = useSelector((state) => state.exchangeList);
  const { currencies } = useSelector((state) => state.currencyList);
  const { cooldownPolicies } = useSelector((state) => state.cooldownPolicies);
  const strategyDetails = useSelector((state) => state.strategyDetails);
  const { strategy, loading, error } = strategyDetails;
  const { marketElasticityPolicies } = useSelector((state) => state.marketElasticityPolicies);
  const strategyUpdate = useSelector((state) => state.strategyUpdate);
  const { success: successEdit, error: errorEdit, strategy: savedStrategy } = strategyUpdate;

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      document.title = "PMDash - Edit Strategy";
      dispatch(detailsStrategy(props.match.params.id));
      if (strategy) {
        setPortfolioName(strategy.portfolioName);
        setExchangeId(strategy.cryptocurrencyExchangeId);
        setStrategyName(strategy.name);
        setBalance(strategy.balance);
        setCurrencySymbol(strategy.currencySymbol);
        setStablecoin(strategy.shouldConvertToStableCoin);
        setBudget(strategy.budget);
        setDescription(strategy.description);
        setNickname(strategy.nickname);
        setMaxNumOfPositions(strategy.maxOpenPositions);
        setOrderType(strategy.orderType);
        setPositionSizeType(strategy.positionSizeType);
        setCooldownPolicyId(strategy.cooldownPolicyId);
        setMarketElasticityPolicyId(strategy.marketElasticityPolicyId);
        setCooldownIntervalInMinutes(strategy.cooldownIntervalInMinutes);
        setPositionSize(strategy.positionSize);
        setBuyDropPercent(strategy.buyDropInPercent);
        setSellSpikePercent(strategy.sellSpikeInPercent);
        setBuyDropThresholdInPercent(strategy.buyDropThresholdInPercent);
        setReinvestProfitPercentage(strategy.reinvestProfitPercentage);
        setSellPositionSizePercentage(strategy.sellPositionSizePercentage);
      }
      dispatch(listPortfolios());
      dispatch(listExchanges());
      dispatch(listCurrencies(1, 100));
      dispatch(listCooldownPolicies());
      dispatch(listMarketElasticityPolicies());
      return () => {};
    })();
  }, [successEdit]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateStrategy({
        id: props.match.params.id,
        name: strategyName,
        budget,
        balance,
        currencySymbol,
        description,
        nickname,
        maxOpenPositions: maxNumOfPositions,
        portfolioName,
        cryptocurrencyExchangeId: exchangeId,
        positionSize,
        buyDropInPercent: buyDropPercent,
        sellSpikeInPercent: sellSpikePercent,
        shouldConvertToStableCoin: stablecoin === "on" ? true : false,
        orderType,
        cooldownIntervalInMinutes,
        cooldownPolicyId,
        marketElasticityPolicyId,
        buyDropThresholdInPercent,
        reinvestProfitPercentage,
        sellPositionSizePercentage,
        positionSizeType,
      })
    );
  };

  return (
    <div className="content content-margined">
      <div>
        <Link to={"/strategy/id/" + props.match.params.id}>
          <button className="button primary">Back to strategy</button>
        </Link>
      </div>
      <div className="form">
        {loading ? (
          <div>Loading</div>
        ) : error ? (
          <div>Failed to load strategy - {error}</div>
        ) : (
          <form onSubmit={submitHandler}>
            <ul className="form-container">
              <li>
                <h2>Edit Strategy</h2>
              </li>
              <li>{errorEdit && <div>{errorEdit}</div>}</li>
              <li>{successEdit ? <div>Strategy Edit Successfuly</div> : null}</li>
              {portfolios ? (
                portfolios.length > 0 ? (
                  <li>
                    <label htmlFor="portfolioName">Portfolio</label>
                    <select value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)}>
                      <option value="⬇️ Select a portfolio ⬇️"> -- Select a portfolio -- </option>
                      {portfolios.map((item) => (
                        <option value={item.name} id={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </li>
                ) : (
                  <li>
                    <label>No Portfolios Found 1</label>
                  </li>
                )
              ) : (
                <li>
                  <label>No Portfolios Found 2</label>
                </li>
              )}
              {exchanges ? (
                exchanges.length > 0 ? (
                  <li>
                    <label htmlFor="exchangeName">Exchange</label>
                    <select value={exchangeId} onChange={(e) => setExchangeId(e.target.value)}>
                      <option value="⬇️ Select an exchange ⬇️"> -- Select an exchange -- </option>
                      {exchanges.map((item) => (
                        <option value={item.id} id={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </li>
                ) : (
                  <li>
                    <label>No Excahnges Found 1</label>
                  </li>
                )
              ) : (
                <li>
                  <label>No Excahnges Found 2</label>
                </li>
              )}
              <li>
                <label htmlFor="name">Name</label>
                <textarea
                  name="name"
                  id="name"
                  rows={5}
                  value={strategyName}
                  onChange={(e) => setStrategyName(e.target.value)}
                ></textarea>
              </li>
              {currencies ? (
                currencies.results ? (
                  <li>
                    <label htmlFor="currency">Currency Symbol</label>
                    <select value={currencySymbol} onChange={(e) => setCurrencySymbol(e.target.value)}>
                      <option value="⬇️ Select a currency ⬇️"> -- Select a currency -- </option>
                      {currencies.results.map((item) => (
                        <option value={item.symbol} id={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </li>
                ) : (
                  <li>
                    <label>No Currencies Found 1</label>
                  </li>
                )
              ) : (
                <li>
                  <label>No Currencies Found 2</label>
                </li>
              )}
              <li>
                <label htmlFor="stablecoin">Should Convert to StableCoin</label>
                <input
                  type="checkbox"
                  name="stablecoin"
                  id="stablecoin"
                  checked={stablecoin}
                  onChange={(e) => setStablecoin(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="balance">Balance</label>
                <input
                  type="number"
                  name="balance"
                  id="balance"
                  value={balance}
                  onChange={(e) => setBalance(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="budget">Budget</label>
                <input
                  type="number"
                  name="budget"
                  id="budget"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </li>
              <li>
                <label htmlFor="nickname">Nick Name</label>
                <textarea
                  name="nickname"
                  id="nickname"
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                ></textarea>
              </li>
              <li>
                <label htmlFor="maxNumOfPositions">Max Number Of Positions</label>
                <input
                  type="text"
                  name="maxNumOfPositions"
                  id="maxNumOfPositions"
                  value={maxNumOfPositions}
                  onChange={(e) => setMaxNumOfPositions(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="orderType">Order Type</label>
                <select value={orderType} onChange={(e) => setOrderType(e.target.value)}>
                  <option value={""}> ⬇️-- Select an order type --⬇️ </option>
                  <option value="limit">Limit</option>
                  <option value="market">Market</option>
                  <option value="stop">Stop</option>
                </select>
              </li>
              <li>
                <label htmlFor="positionSizeType">Position Size Type</label>
                <select value={positionSizeType} onChange={(e) => setPositionSizeType(e.target.value)}>
                  <option value={""}> ⬇️-- Select position size type --⬇️ </option>
                  <option value="value">Value</option>
                  <option value="percent">Percent</option>
                </select>
              </li>
              {cooldownPolicies ? (
                cooldownPolicies.length > 0 ? (
                  <li>
                    <label htmlFor="cooldownPolicy">Cooldown Policy</label>
                    <select value={cooldownPolicyId} onChange={(e) => setCooldownPolicyId(e.target.value)}>
                      <option value={""}>⬇️-- Select a cooldown policy --⬇️ </option>
                      {cooldownPolicies.map((item) => (
                        <option value={item.id} id={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </li>
                ) : (
                  <li>
                    <label>No Cooldown Policy Found 1</label>
                  </li>
                )
              ) : (
                <li>
                  <label>No Cooldown Policies Found 2</label>
                </li>
              )}
              {marketElasticityPolicies ? (
                marketElasticityPolicies.length > 0 ? (
                  <li>
                    <label htmlFor="marketElasticityPolicy">Market Elasticity Policy</label>
                    <select
                      value={marketElasticityPolicyId}
                      onChange={(e) => setMarketElasticityPolicyId(e.target.value)}
                    >
                      <option value={""}> ⬇️-- Select a market elasticit policy --⬇️</option>
                      {marketElasticityPolicies.map((item) => (
                        <option value={item.id} id={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </li>
                ) : (
                  <li>
                    <label>No Market Elasticity Policy Found 1</label>
                  </li>
                )
              ) : (
                <li>
                  <label>No Market Elasticity Policies Found 2</label>
                </li>
              )}
              <li>
                <label htmlFor="cooldownIntervalInMinutes">Cooldown Interval in Minutes</label>
                <input
                  type="number"
                  name="cooldownIntervalInMinutes"
                  id="cooldownIntervalInMinutes"
                  value={cooldownIntervalInMinutes}
                  onChange={(e) => setCooldownIntervalInMinutes(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="positionSize">Position Size</label>
                <input
                  type="number"
                  name="positionSize"
                  id="positionSize"
                  value={positionSize}
                  onChange={(e) => setPositionSize(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="buyDropPercent">Buy Drop Percent</label>
                <input
                  type="number"
                  name="buyDropPercent"
                  id="buyDropPercent"
                  value={buyDropPercent}
                  onChange={(e) => setBuyDropPercent(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="sellSpikePercent">Sell Spike Percent</label>
                <input
                  type="number"
                  name="sellSpikePercent"
                  id="sellSpikePercent"
                  value={sellSpikePercent}
                  onChange={(e) => setSellSpikePercent(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="buyDropThresholdInPercent">Buy Drop Threshold Percent (Optional) </label>
                <input
                  type="number"
                  name="buyDropThresholdInPercent"
                  id="buyDropThresholdInPercent"
                  value={buyDropThresholdInPercent}
                  onChange={(e) => setBuyDropThresholdInPercent(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="reinvestProfitPercentage">Reinvest Profit Percentage (Optional) </label>
                <input
                  type="number"
                  name="reinvestProfitPercentage"
                  id="reinvestProfitPercentage"
                  value={reinvestProfitPercentage}
                  onChange={(e) => setReinvestProfitPercentage(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="sellPositionSizePercentage">Sell Position Size Percentage (Optional) </label>
                <input
                  type="number"
                  name="sellPositionSizePercentage"
                  id="sellPositionSizePercentage"
                  min="0.00"
                  step="0.001"
                  presicion={2}
                  value={sellPositionSizePercentage}
                  onChange={(e) => setSellPositionSizePercentage(e.target.value)}
                ></input>
              </li>
              <li>
                <button type="submit" className="button primary">
                  Update
                </button>
              </li>
              <li>
                <button type="button" className="button secondary">
                  Back
                </button>
              </li>
            </ul>
          </form>
        )}
      </div>
    </div>
  );
}

export default StrategyEditScreen;
