import axios from "axios";
import {
  CURRENCY_BALANCE_FAILURE,
  CURRENCY_BALANCE_REQUEST,
  CURRENCY_BALANCE_SUCCESS,
  CURRENCY_TICKER_FAILURE,
  CURRENCY_TICKER_REQUEST,
  CURRENCY_TICKER_SUCCESS,
  EXCHANGE_LIST_FAILURE,
  EXCHANGE_LIST_REQUEST,
  EXCHANGE_LIST_SUCCESS,
} from "../constants/exchangeConstants";

const tickerCurrencyExchange = (symbol, exchange) => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_TICKER_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/exchange/ticker/" + symbol, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: CURRENCY_TICKER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CURRENCY_TICKER_FAILURE, payload: error.message });
  }
};

const balanceCurrencyExchange = (symbol, exchange) => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_BALANCE_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/exchange/balance/" + symbol, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: CURRENCY_BALANCE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CURRENCY_BALANCE_FAILURE, payload: error.message });
  }
};

const listExchanges = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EXCHANGE_LIST_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/exchange", {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: EXCHANGE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: EXCHANGE_LIST_FAILURE, payload: error.message });
  }
};

export { tickerCurrencyExchange, balanceCurrencyExchange, listExchanges };
