import axios from "axios";
import {
  COOLDOWN_POLICY_LIST_FAILURE,
  COOLDOWN_POLICY_LIST_REQUEST,
  COOLDOWN_POLICY_LIST_SUCCESS,
  MARKET_ELASTICITY_POLICY_LIST_FAILURE,
  MARKET_ELASTICITY_POLICY_LIST_REQUEST,
  MARKET_ELASTICITY_POLICY_LIST_SUCCESS,
} from "../constants/policyConstants";

const listCooldownPolicies = () => async (dispatch, getState) => {
  try {
    dispatch({ type: COOLDOWN_POLICY_LIST_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/policy/cooldown", {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: COOLDOWN_POLICY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COOLDOWN_POLICY_LIST_FAILURE, payload: error.message });
  }
};

const listMarketElasticityPolicies = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MARKET_ELASTICITY_POLICY_LIST_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/policy/marketElasticity", {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: MARKET_ELASTICITY_POLICY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MARKET_ELASTICITY_POLICY_LIST_FAILURE, payload: error.message });
  }
};

export { listCooldownPolicies, listMarketElasticityPolicies };
