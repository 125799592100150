export const PORTFOLIO_DETAILS_REQUEST = "PORTFOLIO_DETAILS_REQUEST";
export const PORTFOLIO_DETAILS_SUCCESS = "PORTFOLIO_DETAILS_SUCCESS";
export const PORTFOLIO_DETAILS_FAILURE = "PORTFOLIO_DETAILS_FAILURE";

export const PORTFOLIO_LIST_REQUEST = "PORTFOLIO_LIST_REQUEST";
export const PORTFOLIO_LIST_SUCCESS = "PORTFOLIO_LIST_SUCCESS";
export const PORTFOLIO_LIST_FAILURE = "PORTFOLIO_LIST_FAILURE";

export const PORTFOLIO_SAVE_REQUEST = "PORTFOLIO_SAVE_REQUEST";
export const PORTFOLIO_SAVE_SUCCESS = "PORTFOLIO_SAVE_SUCCESS";
export const PORTFOLIO_SAVE_FAILURE = "PORTFOLIO_SAVE_FAILURE";

export const PORTFOLIO_DEACTIVATE_REQUEST = 'PORTFOLIO_DEACTIVATE_REQUEST';
export const PORTFOLIO_DEACTIVATE_SUCCESS = 'PORTFOLIO_DEACTIVATE_SUCCESS';
export const PORTFOLIO_DEACTIVATE_FAILURE = 'PORTFOLIO_DEACTIVATE_FAILURE';
