import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listCurrencies, saveCurrency } from "../actions/currencyActions";
import ReactPaginate from "react-paginate";

function CurrenciesScreen(props) {
  const [modelVisible, setModelVisiable] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const { currencies: currencies, loading } = useSelector((state) => state.currencyList);
  const currencySave = useSelector((state) => state.currencySave);
  const { loading: loadingSave, success: successSave, error: errorSave } = currencySave;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  // Paging
  let [rowCount, setRowCount] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "PMDash - Currencies";
    if (!userInfo) {
      props.history.push("/signin?redirect=currencies");
    }
    if (successSave) {
      setModelVisiable(false);
    }
    dispatch(listCurrencies(itemOffset, itemsPerPage));
    setPageCount(currencies.totalNumberOfPages);
    return () => {};
  }, [currentPage, dispatch]);

  const handlePageClick = (e) => {
    const newOffset = e.selected * itemsPerPage;
    console.log(`User requested page number ${e.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
    setRowCount(newOffset + 1);
    setCurrentPage(e.selected);
    console.log(`Returned ${currencies.results.length} out of ${currencies.totalNumberOfRecords}.`);
  };

  const openModel = (currency) => {
    setModelVisiable(true);
    setId(currency._id);
    setName(currency.name);
    setSymbol(currency.symbol);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveCurrency({ _id: id, name, symbol }));
  };

  return (
    <div className="content content-margined">
      <h3>Currencies</h3>
      <div>
        <button className="button primary" onClick={() => openModel({})}>
          Create New Currency
        </button>
      </div>
      {modelVisible && (
        <div className="form">
          <form onSubmit={submitHandler}>
            <ul className="form-container">
              <li>
                <h2>Create Currency</h2>
              </li>
              <li>
                {loadingSave && <div>Loading</div>}
                {errorSave && <div>{errorSave}</div>}
              </li>
              <li>
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)}></input>
              </li>
              <li>
                <label htmlFor="symbol">Symbol</label>
                <input
                  type="text"
                  name="symbol"
                  id="symbol"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                ></input>
              </li>
              <li>
                <button type="submit" className="button primary">
                  {id ? "Update" : "Create"}
                </button>
              </li>
              <li>
                <button type="button" onClick={() => setModelVisiable(false)} className="button secondary">
                  Back
                </button>
              </li>
            </ul>
          </form>
        </div>
      )}
      <div className="currency-list"></div>
      {loading ? (
        <div>Loading Currencies</div>
      ) : currencies && currencies.results ? (
        <div className="currency-header">
          <div>
            <label>
              Showing results - {currentPage * itemsPerPage + 1} to{" "}
              {currentPage * itemsPerPage + currencies.results.length} from {currencies.totalNumberOfRecords}
            </label>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Symbol</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currencies.results.map((currency) => (
                  <tr key={currency.id}>
                    <td>{rowCount++}</td>
                    <td>
                      <Link to={"/currency/symbol/" + currency.symbol}>{currency.name}</Link>
                    </td>
                    <td>{currency.symbol}</td>
                    <td>
                      <button className="button" onClick={() => openModel(currency)}>
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"< previous"}
              nextLabel={"next >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              forcePage={currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CurrenciesScreen;
