import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { detailsStrategy, listStrategyHistory } from "../actions/strategyActions";
import Moment from "react-moment";

function StrategyHistoriesScreen(props) {
  const strategyDetails = useSelector((state) => state.strategyDetails);
  const { strategy, loading, error } = strategyDetails;
  const strategyHistories = useSelector((state) => state.strategyHistories);
  const { histories, loading: historyLoading, error: historyError } = strategyHistories;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsStrategy(props.match.params.id));
    dispatch(listStrategyHistory(props.match.params.id));
    return () => {};
  }, []);

  let historyRowCount = 1;

  return (
    <div>
      <div className="back-to-home">
        <Link to={"/strategy/id/" + props.match.params.id}>Back to Strategy</Link>
      </div>
      {loading || historyLoading ? (
        <div>Loading..</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div className="details">
          <div className="details-info">
            <ul>
              <li>
                <h3>Current Details</h3>
                <h4>Name - {strategy.name}</h4>
                <h4>Currency - {strategy.currencyName}</h4>
              </li>
            </ul>
            <div>
              <h3>Stratgey Histories</h3>
              {histories.length > 0 ? (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Version</th>
                        <th>Date</th>
                        <th>Strategy Name</th>
                        <th>Exhcnage Name</th>
                        <th>Order Type</th>
                        <th>Status</th>
                        <th>Budget</th>
                        <th>Balance</th>
                        <th>Buy Drop</th>
                        <th>Sell Spike</th>
                        <th>Sell Position Size Percentage</th>
                        <th>Buy Drop Threshold Percentage</th>
                        <th>Reinvest Profit Percentage</th>
                        <th>Position Size Type</th>
                        <th>Position Size</th>
                        <th>Market Elasticity Policy</th>
                        <th>Cooldown Policy</th>
                        <th>Max Positions</th>
                        <th>Portfolio Name</th>
                        <th>Nick name</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {histories.map((history) => {
                        let previousHistoy = histories[historyRowCount - 2];
                        let nextHistory = histories[historyRowCount + 1];
                        return (
                          <tr>
                            <td>{historyRowCount++}</td>
                            <td>{history.version}</td>
                            <td>
                              <Moment>{history.createdOn}</Moment>
                            </td>
                            <td>
                              {previousHistoy && history.name !== previousHistoy.name ? (
                                <td style={{ background: "deeppink" }}>{history.name}</td>
                              ) : nextHistory && nextHistory.name !== history.name ? (
                                <td style={{ background: "green" }}>{history.name}</td>
                              ) : historyRowCount === histories.length + 1 && strategy.name !== history.name ? (
                                <td style={{ background: "blue" }}>{history.name}</td>
                              ) : (
                                <td>{history.name}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy &&
                              history.cryptocurrencyExchangeName !== previousHistoy.cryptocurrencyExchangeName ? (
                                <td style={{ background: "deeppink" }}>{history.cryptocurrencyExchangeName}</td>
                              ) : nextHistory &&
                                nextHistory.cryptocurrencyExchangeName !== history.cryptocurrencyExchangeName ? (
                                <td style={{ background: "green" }}>{history.cryptocurrencyExchangeName}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                strategy.cryptocurrencyExchangeName !== history.cryptocurrencyExchangeName ? (
                                <td style={{ background: "blue" }}>{history.cryptocurrencyExchangeName}</td>
                              ) : (
                                <td>{history.cryptocurrencyExchangeName}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy && history.orderType !== previousHistoy.orderType ? (
                                <td style={{ background: "deeppink" }}>{history.orderType}</td>
                              ) : nextHistory && nextHistory.orderType !== history.orderType ? (
                                <td style={{ background: "green" }}>{history.orderType}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                strategy.orderType !== history.orderType ? (
                                <td style={{ background: "blue" }}>{history.orderType}</td>
                              ) : (
                                <td>{history.orderType}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy && history.status !== previousHistoy.status ? (
                                <td style={{ background: "deeppink" }}>{history.status}</td>
                              ) : nextHistory && nextHistory.status !== history.status ? (
                                <td style={{ background: "green" }}>{history.status}</td>
                              ) : historyRowCount === histories.length + 1 && strategy.status !== history.status ? (
                                <td style={{ background: "blue" }}>{history.status}</td>
                              ) : (
                                <td>{history.status}</td>
                              )}
                            </td>
                            <td>{history.budget}</td>
                            <td>{history.balance}</td>
                            <td>
                              {previousHistoy && history.buyDropInPercent !== previousHistoy.buyDropInPercent ? (
                                <td style={{ background: "deeppink" }}>{history.buyDropInPercent}</td>
                              ) : nextHistory && nextHistory.buyDropInPercent !== history.buyDropInPercent ? (
                                <td style={{ background: "green" }}>{history.buyDropInPercent}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                strategy.buyDropInPercent !== history.buyDropInPercent ? (
                                <td style={{ background: "blue" }}>{history.buyDropInPercent}</td>
                              ) : (
                                <td>{history.buyDropInPercent}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy && history.sellSpikeInPercent !== previousHistoy.sellSpikeInPercent ? (
                                <td style={{ background: "deeppink" }}>{history.sellSpikeInPercent}</td>
                              ) : nextHistory && nextHistory.sellSpikeInPercent !== history.sellSpikeInPercent ? (
                                <td style={{ background: "green" }}>{history.sellSpikeInPercent}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                strategy.sellSpikeInPercent !== history.sellSpikeInPercent ? (
                                <td style={{ background: "blue" }}>{history.sellSpikeInPercent}</td>
                              ) : (
                                <td>{history.sellSpikeInPercent}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy &&
                              history.sellPositionSizePercentage &&
                              history.sellPositionSizePercentage !== previousHistoy.sellPositionSizePercentage ? (
                                <td style={{ background: "deeppink" }}>{history.sellPositionSizePercentage}</td>
                              ) : nextHistory &&
                                history.sellPositionSizePercentage &&
                                nextHistory.sellPositionSizePercentage !== history.sellPositionSizePercentage ? (
                                <td style={{ background: "green" }}>{history.sellPositionSizePercentage}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                history.sellPositionSizePercentage &&
                                strategy.sellPositionSizePercentage !== history.sellPositionSizePercentage ? (
                                <td style={{ background: "blue" }}>{history.sellPositionSizePercentage}</td>
                              ) : (
                                <td>{history.sellPositionSizePercentage}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy &&
                              history.buyDropThresholdInPercent &&
                              history.buyDropThresholdInPercent !== previousHistoy.buyDropThresholdInPercent ? (
                                <td style={{ background: "deeppink" }}>{history.buyDropThresholdInPercent}</td>
                              ) : nextHistory &&
                                history.buyDropThresholdInPercent &&
                                nextHistory.buyDropThresholdInPercent !== history.buyDropThresholdInPercent ? (
                                <td style={{ background: "green" }}>{history.buyDropThresholdInPercent}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                history.buyDropThresholdInPercent &&
                                strategy.buyDropThresholdInPercent !== history.buyDropThresholdInPercent ? (
                                <td style={{ background: "blue" }}>{history.buyDropThresholdInPercent}</td>
                              ) : (
                                <td>{history.buyDropThresholdInPercent}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy &&
                              history.reinvestProfitPercentage &&
                              history.reinvestProfitPercentage !== previousHistoy.reinvestProfitPercentage ? (
                                <td style={{ background: "deeppink" }}>{history.reinvestProfitPercentage}</td>
                              ) : nextHistory &&
                                history.reinvestProfitPercentage &&
                                nextHistory.reinvestProfitPercentage !== history.reinvestProfitPercentage ? (
                                <td style={{ background: "green" }}>{history.reinvestProfitPercentage}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                history.reinvestProfitPercentage &&
                                strategy.reinvestProfitPercentage !== history.reinvestProfitPercentage ? (
                                <td style={{ background: "blue" }}>{history.reinvestProfitPercentage}</td>
                              ) : (
                                <td>{history.reinvestProfitPercentage}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy && history.positionSizeType !== previousHistoy.positionSizeType ? (
                                <td style={{ background: "deeppink" }}>{history.positionSizeType}</td>
                              ) : nextHistory && nextHistory.positionSizeType !== history.positionSizeType ? (
                                <td style={{ background: "green" }}>{history.positionSizeType}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                strategy.positionSizeType !== history.positionSizeType ? (
                                <td style={{ background: "blue" }}>{history.positionSizeType}</td>
                              ) : (
                                <td>{history.positionSizeType}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy && history.positionSize !== previousHistoy.positionSize ? (
                                <td style={{ background: "deeppink" }}>{history.positionSize}</td>
                              ) : nextHistory && nextHistory.positionSize !== history.positionSize ? (
                                <td style={{ background: "green" }}>{history.positionSize}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                strategy.positionSize !== history.positionSize ? (
                                <td style={{ background: "blue" }}>{history.positionSize}</td>
                              ) : (
                                <td>{history.positionSize}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy &&
                              history.marketElasticityPolicyId &&
                              history.marketElasticityPolicyId !== previousHistoy.marketElasticityPolicyId ? (
                                <td style={{ background: "deeppink" }}>{history.marketElasticityPolicyId}</td>
                              ) : nextHistory &&
                                history.marketElasticityPolicyId &&
                                nextHistory.marketElasticityPolicyId !== history.marketElasticityPolicyId ? (
                                <td style={{ background: "green" }}>{history.marketElasticityPolicyId}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                history.marketElasticityPolicyId &&
                                strategy.marketElasticityPolicyId !== history.marketElasticityPolicyId ? (
                                <td style={{ background: "blue" }}>{history.marketElasticityPolicyId}</td>
                              ) : (
                                <td>{history.marketElasticityPolicyId}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy &&
                              history.cooldownPolicyId &&
                              history.cooldownPolicyId !== previousHistoy.cooldownPolicyId ? (
                                <td style={{ background: "deeppink" }}>{history.cooldownPolicyId}</td>
                              ) : nextHistory &&
                                history.cooldownPolicyId &&
                                nextHistory.cooldownPolicyId !== history.cooldownPolicyId ? (
                                <td style={{ background: "green" }}>{history.cooldownPolicyId}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                history.cooldownPolicyId &&
                                strategy.cooldownPolicyId !== history.cooldownPolicyId ? (
                                <td style={{ background: "blue" }}>{history.cooldownPolicyId}</td>
                              ) : (
                                <td>{history.cooldownPolicyId}</td>
                              )}
                            </td>
                            <td>
                              {previousHistoy && history.maxOpenPositions !== previousHistoy.maxOpenPositions ? (
                                <td style={{ background: "deeppink" }}>{history.maxOpenPositions}</td>
                              ) : nextHistory && nextHistory.maxOpenPositions !== history.maxOpenPositions ? (
                                <td style={{ background: "green" }}>{history.maxOpenPositions}</td>
                              ) : historyRowCount === histories.length + 1 &&
                                strategy.maxOpenPositions !== history.maxOpenPositions ? (
                                <td style={{ background: "blue" }}>{history.maxOpenPositions}</td>
                              ) : (
                                <td>{history.maxOpenPositions}</td>
                              )}
                            </td>
                            <td>{history.portfolioName}</td>
                            <td>{history.nickname}</td>
                            <td>{history.description}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No Exchange Settings</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StrategyHistoriesScreen;
