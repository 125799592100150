import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStrategy } from "../actions/strategyActions";
import { listPortfolios } from "../actions/portfolioActions";
import { listExchanges } from "../actions/exchangeActions";
import { listCurrencies } from "../actions/currencyActions";
import { listCooldownPolicies, listMarketElasticityPolicies } from "../actions/policyActions";

function StrategyCreateScreen(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [maxNumOfPositions, setMaxNumOfPositions] = useState("");
  const [nickname, setNickname] = useState("");
  const [stablecoin, setStablecoin] = useState(false);
  const [budget, setBudget] = useState("");
  const [orderType, setOrderType] = useState("");
  const [portfolioName, setPortfolioName] = useState("");
  const [positionSize, setPositionSize] = useState("");
  const [sellSpikePercent, setSellSpikePercent] = useState("");
  const [buyDropPercent, setBuyDropPercent] = useState("");
  const [buyDropThresholdInPercent, setBuyDropThresholdInPercent] = useState("");
  const [reinvestProfitPercentage, setReinvestProfitPercentage] = useState("");
  const [exchangeId, setExchangeId] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [cooldownIntervalInMinutes, setCooldownIntervalInMinutes] = useState("");
  const [cooldownPolicyId, setCooldownPolicyId] = useState("");
  const [marketElasticityPolicyId, setMarketElasticityPolicyId] = useState("");
  const [sellPositionSizePercentage, setSellPositionSizePercentage] = useState("");
  const [positionSizeType, setPositionSizeType] = useState("");
  const { portfolios } = useSelector((state) => state.portfolioList);
  const { exchanges } = useSelector((state) => state.exchangeList);
  const { currencies } = useSelector((state) => state.currencyList);
  const { cooldownPolicies } = useSelector((state) => state.cooldownPolicies);
  const { marketElasticityPolicies } = useSelector((state) => state.marketElasticityPolicies);
  const strategyCreate = useSelector((state) => state.strategyCreate);
  const { success: successCreate, error: errorCreate } = strategyCreate;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "PMDash - Create Strategy";
    dispatch(listPortfolios());
    dispatch(listExchanges());
    dispatch(listCurrencies(1, 100));
    dispatch(listCooldownPolicies());
    dispatch(listMarketElasticityPolicies());
    return () => {};
  }, [successCreate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createStrategy({
        name,
        budget,
        currencySymbol,
        description,
        nickname,
        maxOpenPositions: maxNumOfPositions,
        portfolioName,
        CryptocurrencyExchangeId: exchangeId,
        positionSize,
        buyDropInPercent: buyDropPercent,
        SellSpikeInPercent: sellSpikePercent,
        ShouldConvertToStableCoin: stablecoin,
        orderType,
        cooldownIntervalInMinutes,
        cooldownPolicyId,
        marketElasticityPolicyId,
        buyDropThresholdInPercent,
        reinvestProfitPercentage,
        sellPositionSizePercentage,
        positionSizeType,
      })
    );
  };

  const handleStableCoin = () => {
    setStablecoin(!stablecoin);
  };

  return (
    <div className="content content-margined">
      <div className="form">
        <form onSubmit={submitHandler}>
          <ul className="form-container">
            <li>
              <h2>Create Strategy</h2>
            </li>
            <li>{errorCreate && <div>{errorCreate}</div>}</li>
            <li>{successCreate && <div>Strategy Created Successfuly</div>}</li>
            {portfolios ? (
              portfolios.length > 0 ? (
                <li>
                  <label htmlFor="portfolioName">Portfolio</label>
                  <select value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)}>
                    <option value="⬇️ Select a portfolio ⬇️"> -- Select a portfolio -- </option>
                    {portfolios.map((item) => (
                      <option value={item.name} id={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </li>
              ) : (
                <li>
                  {" "}
                  <label>No Portfolios Found 1</label>
                </li>
              )
            ) : (
              <li>
                {" "}
                <label>No Portfolios Found 2</label>
              </li>
            )}
            {exchanges ? (
              exchanges.length > 0 ? (
                <li>
                  <label htmlFor="exchangeName">Exchange</label>
                  <select value={exchangeId} onChange={(e) => setExchangeId(e.target.value)}>
                    <option value="⬇️ Select an exchange ⬇️"> -- Select an exchange -- </option>
                    {exchanges.map((item) => (
                      <option value={item.id} id={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </li>
              ) : (
                <li>
                  {" "}
                  <label>No Excahnges Found 1</label>
                </li>
              )
            ) : (
              <li>
                {" "}
                <label>No Excahnges Found 2</label>
              </li>
            )}
            <li>
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)}></input>
            </li>
            {currencies ? (
              currencies.results ? (
                <li>
                  <label htmlFor="currency">Currency Symbol</label>
                  <select value={currencySymbol} onChange={(e) => setCurrencySymbol(e.target.value)}>
                    <option value="⬇️ Select a currency ⬇️"> -- Select a currency -- </option>
                    {currencies.results.map((item) => (
                      <option value={item.symbol} id={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </li>
              ) : (
                <li>
                  {" "}
                  <label>No Currencies Found 1</label>
                </li>
              )
            ) : (
              <li>
                {" "}
                <label>No Currencies Found 2</label>
              </li>
            )}
            <li>
              <label htmlFor="stablecoin">Should Convert to StableCoin</label>
              <input
                type="checkbox"
                name="stablecoin"
                id="stablecoin"
                checked={stablecoin}
                //onChange={(e) => setStablecoin(e.target.value)}
                onChange={handleStableCoin}
              ></input>
            </li>
            <li>
              <label htmlFor="budget">Budget</label>
              <input
                type="number"
                name="budget"
                id="budget"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              ></input>
            </li>
            <li>
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </li>
            <li>
              <label htmlFor="nickname">Nick Name</label>
              <textarea
                name="nickname"
                id="nickname"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
              ></textarea>
            </li>
            <li>
              <label htmlFor="maxNumOfPositions">Max Number Of Positions</label>
              <input
                type="text"
                name="maxNumOfPositions"
                id="maxNumOfPositions"
                value={maxNumOfPositions}
                onChange={(e) => setMaxNumOfPositions(e.target.value)}
              ></input>
            </li>
            <li>
              <label htmlFor="orderType">Order Type</label>
              <select value={orderType} onChange={(e) => setOrderType(e.target.value)}>
                <option value="⬇️ Select an order type ⬇️"> -- Select an order type -- </option>
                <option value="limit">Limit</option>
                <option value="market">Market</option>
                <option value="stop">Stop</option>
              </select>
            </li>
            <li>
              <label htmlFor="positionSizeType">Position Size Type</label>
              <select value={positionSizeType} onChange={(e) => setPositionSizeType(e.target.value)}>
                <option value="⬇️ Select position size type ⬇️"> -- Select position size type -- </option>
                <option value="value">Value</option>
                <option value="percent">Percent</option>
              </select>
            </li>
            {cooldownPolicies ? (
              cooldownPolicies.length > 0 ? (
                <li>
                  <label htmlFor="cooldownPolicy">Cooldown Policy</label>
                  <select value={cooldownPolicyId} onChange={(e) => setCooldownPolicyId(e.target.value)}>
                    <option value="⬇️ Select a cooldown policy ⬇️"> -- Select a cooldown policy -- </option>
                    {cooldownPolicies.map((item) => (
                      <option value={item.id} id={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </li>
              ) : (
                <li>
                  {" "}
                  <label>No Cooldown Policy Found 1</label>
                </li>
              )
            ) : (
              <li>
                {" "}
                <label>No Cooldown Policies Found 2</label>
              </li>
            )}
            {marketElasticityPolicies ? (
              marketElasticityPolicies.length > 0 ? (
                <li>
                  <label htmlFor="marketElasticityPolicy">Market Elasticity Policy</label>
                  <select
                    value={marketElasticityPolicyId}
                    onChange={(e) => setMarketElasticityPolicyId(e.target.value)}
                  >
                    <option value="⬇️ Select a market elasticit policy ⬇️">
                      {" "}
                      -- Select a market elasticit policy --{" "}
                    </option>
                    {marketElasticityPolicies.map((item) => (
                      <option value={item.id} id={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </li>
              ) : (
                <li>
                  {" "}
                  <label>No Market Elasticity Policy Found 1</label>
                </li>
              )
            ) : (
              <li>
                {" "}
                <label>No Market Elasticity Policies Found 2</label>
              </li>
            )}
            <li>
              <label htmlFor="cooldownIntervalInMinutes">Cooldown Interval in Minutes</label>
              <input
                type="number"
                name="cooldownIntervalInMinutes"
                id="cooldownIntervalInMinutes"
                value={cooldownIntervalInMinutes}
                onChange={(e) => setCooldownIntervalInMinutes(e.target.value)}
              ></input>
            </li>
            <li>
              <label htmlFor="positionSize">Position Size</label>
              <input
                type="number"
                name="positionSize"
                id="positionSize"
                value={positionSize}
                onChange={(e) => setPositionSize(e.target.value)}
              ></input>
            </li>
            <li>
              <label htmlFor="buyDropPercent">Buy Drop Percent</label>
              <input
                type="number"
                name="buyDropPercent"
                id="buyDropPercent"
                value={buyDropPercent}
                onChange={(e) => setBuyDropPercent(e.target.value)}
              ></input>
            </li>
            <li>
              <label htmlFor="sellSpikePercent">Sell Spike Percent</label>
              <input
                type="number"
                name="sellSpikePercent"
                id="sellSpikePercent"
                value={sellSpikePercent}
                onChange={(e) => setSellSpikePercent(e.target.value)}
              ></input>
            </li>
            <li>
              <label htmlFor="buyDropThresholdInPercent">Buy Drop Threshold Percent (Optional) </label>
              <input
                type="number"
                name="buyDropThresholdInPercent"
                id="buyDropThresholdInPercent"
                value={buyDropThresholdInPercent}
                onChange={(e) => setBuyDropThresholdInPercent(e.target.value)}
              ></input>
            </li>
            <li>
              <label htmlFor="sellPositionSizePercentage">Sell Position Size Percentage (Optional) </label>
              <input
                type="number"
                name="sellPositionSizePercentage"
                id="sellPositionSizePercentage"
                min="0.00"
                step="0.001"
                presicion={2}
                value={sellPositionSizePercentage}
                onChange={(e) => setSellPositionSizePercentage(e.target.value)}
              ></input>
            </li>
            <li>
              <label htmlFor="sellPositionSizePercentage">Sell Position Size Percentage (Optional) </label>
              <input
                type="number"
                name="sellPositionSizePercentage"
                id="sellPositionSizePercentage"
                value={sellPositionSizePercentage}
                onChange={(e) => setSellPositionSizePercentage(e.target.value)}
              ></input>
            </li>
            <li>
              <button type="submit" className="button primary">
                Create
              </button>
            </li>
            <li>
              <button type="button" className="button secondary">
                Back
              </button>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
}

export default StrategyCreateScreen;
