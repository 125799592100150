import axios from "axios";
import {
  POSITION_LIST_FAILURE,
  POSITION_LIST_REQUEST,
  POSITION_LIST_SUCCESS,
  POSITION_SAVE_FAILURE,
  POSITION_SAVE_REQUEST,
  POSITION_SAVE_SUCCESS,
  POSITION_SEARCH_FAILURE,
  POSITION_SEARCH_REQUEST,
  POSITION_SEARCH_SUCCESS,
} from "../constants/positionConstants";

const listPositions = (offset = 0, size = 0, currencySymbol = "", states = {}) => async (dispatch, getState) => {
  try {
    dispatch({ type: POSITION_LIST_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    let url = "/api/position/paged?offset=" + offset + "&size=" + size;

    if (currencySymbol !== "") {
      url += "&currencySymbol=" + currencySymbol;
    }

    if(states && states.length > 0){
      url += "&states=";
    }

    const { data } = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: POSITION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: POSITION_LIST_FAILURE, payload: error.message });
  }
};

const listOpenPositions = (offset = 0, size = 0, currencySymbol = "") => async (dispatch, getState) => {
  try {
    dispatch({ type: POSITION_LIST_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();

    let url = "/api/position/paged?positionStates=Open&offset=" + offset + "&size=" + size;

    if (currencySymbol !== "") {
      url += "&currencySymbol=" + currencySymbol;
    }
    
    const { data } = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: POSITION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: POSITION_LIST_FAILURE, payload: error.message });
  }
};

const searchPositionsByStrategyId = (strategyId) => async (dispatch, getState) => {
  try {
    dispatch({ type: POSITION_SEARCH_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/position?size=0&strategyId=" + strategyId, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: POSITION_SEARCH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: POSITION_SEARCH_FAILURE, payload: error.message });
  }
};

const searchPositionsByCurrency = (currencySymbol) => async (dispatch, getState) => {
  try {
    dispatch({ type: POSITION_SEARCH_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await axios.get("/api/position?size=0&currencySymbol=" + currencySymbol, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });

    dispatch({ type: POSITION_SEARCH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: POSITION_SEARCH_FAILURE, payload: error.message });
  }
};

const savePosition = (position) => async (dispatch, getState) => {
  try {
    dispatch({ type: POSITION_SAVE_REQUEST, payload: position });
    const {
      userSignin: { userInfo },
    } = getState();
    if (position._id) {
      const { data } = await axios.put("/api/position/" + position._id, position, {
        headers: {
          Authorization: "Bearer " + userInfo.token,
        },
      });
      dispatch({ type: POSITION_SAVE_SUCCESS, payload: data });
    } else {
      const { data } = await axios.post("/api/position", position, {
        headers: {
          Authorization: "Bearer " + userInfo.token,
        },
      });
      dispatch({ type: POSITION_SAVE_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({ type: POSITION_SAVE_FAILURE, payload: error.message });
  }
};

export { listPositions, savePosition, searchPositionsByStrategyId, listOpenPositions, searchPositionsByCurrency };
