import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import Cookie from "js-cookie";
import { userSigninReducer } from "./reducers/userReducers";
import {
  currencyDetailsBySymbolReducer,
  currencyDetailsReducer,
  currencyListReducer,
  currencySaveReducer,
} from "./reducers/currencyReducers";
import {
  strategyActivateReducer,
  strategyCreateReducer,
  strategyDeactivateReducer,
  strategyDetailsReducer,
  strategyHistoriesReducer,
  strategyListReducer,
  strategySaveReducer,
  strategyUpdateReducer,
} from "./reducers/strategyReducers";
import { portfolioDetailsReducer, portfolioListReducer, portfolioSaveReducer } from "./reducers/portfolioReducers";
import {
  positionDetailsReducer,
  positionListReducer,
  positionOpenListReducer,
  positionSaveReducer,
  positionSearchByStrategyIdReducer,
  positionSearchByCurrencyReducer,
} from "./reducers/positionReducers";
import { reportSnapshotReducer } from "./reducers/reportReducers";
import {
  currencyBalanceExchangeReducer,
  currencyTickerExchangeReducer,
  exchangeListReducer,
} from "./reducers/exchangeReducers";
import { cooldownPolicyListReducer, marketElasticityPoliciesListReducer } from "./reducers/policyReducer";
import { testNotificationReducer } from "./reducers/notificationReducres";

const userInfo = Cookie.getJSON("userInfo") || null;

const initialState = {
  userSignin: { userInfo },
};

const reducer = combineReducers({
  userSignin: userSigninReducer,
  currencySave: currencySaveReducer,
  currencyList: currencyListReducer,
  currencyDetails: currencyDetailsReducer,
  currencyDetailsBySymbol: currencyDetailsBySymbolReducer,
  strategyList: strategyListReducer,
  strategyHistories : strategyHistoriesReducer,
  strategyDetails: strategyDetailsReducer,
  strategyUpdate: strategyUpdateReducer,
  strategyDeactivate: strategyDeactivateReducer,
  strategyActivate: strategyActivateReducer,
  strategyCreate: strategyCreateReducer,
  portfolioSave: portfolioSaveReducer,
  portfolioList: portfolioListReducer,
  portfolioDetails: portfolioDetailsReducer,
  positionSave: positionSaveReducer,
  positionList: positionListReducer,
  positionOpenList: positionOpenListReducer,
  positionDetails: positionDetailsReducer,
  positionSearchByStrategyId: positionSearchByStrategyIdReducer,
  positionSearchByCurrency: positionSearchByCurrencyReducer,
  snapshotReport: reportSnapshotReducer,
  exchangeTicker: currencyTickerExchangeReducer,
  exchangeBalance: currencyBalanceExchangeReducer,
  exchangeList: exchangeListReducer,
  cooldownPolicies: cooldownPolicyListReducer,
  marketElasticityPolicies: marketElasticityPoliciesListReducer,
  notificationTest: testNotificationReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));
export default store;
